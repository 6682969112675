import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";

// export const API_BASE_URL = 'http://localhost:3033/lmback'; // 请替换为您的实际 API 基础 URL
export const API_BASE_URL = 'https://api.lf888.co.uk/lmback'; // 请替换为您的实际 API 基础 URL

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 添加请求拦截器来自动添加 token
api.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('jwt');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const sendVerificationCode = async (email) => {
  try {
    const response = await api.post('/send-verification-code', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (email, verificationCode) => {
  try {
    const response = await api.post('/login', { email, verificationCode });
    return response.data; // 应该包含 { user: {...}, token: '...' }
  } catch (error) {
    throw error;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await api.get('/user-info');
    console.log(response.data.user.shop.name)
    return response.data.user;
  } catch (error) {
    console.error('获取用户信息时出错:', error);
    throw error;
  }
};

// 新添加的管理员相关功能

export const getAdminList = async (params) => {
  try {
    const response = await api.get('/admin-list', { params });
    return response.data;
  } catch (error) {
    console.error('获取管理员列表时出错:', error);
    throw error;
  }
};

export const addAdmin = async (adminData) => {
  try {
    const response = await api.post('/add-admin', adminData);
    return response.data;
  } catch (error) {
    console.error('添加管理员时出错:', error);
    throw error;
  }
};

export const deleteAdmin = async (adminId) => {
  try {
    const response = await api.delete(`/delete-admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error('删除管理员时出错:', error);
    throw error;
  }
};

// 分类管理
export const createCategory = async (formData) => {
  const response = await api.post('/categories', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const getCategoryList = async () => {
  const response = await api.get('/categories');
  return response.data;
};

export const updateCategory = async (id, formData) => {
  const response = await api.put(`/categories/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteCategory = async (id) => {
  const response = await api.delete(`/categories/${id}`);
  return response.data;
};

// 产品管理
export const createProduct = async (productData) => {
  const response = await api.post('/products', productData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const getProductList = async (params) => {
  try {
    const response = await api.get('/products', { params });
    return response.data;
  } catch (error) {
    console.error('获取产品列表时出错:', error);
    throw error;
  }
};

export const updateProduct = async (id, productData) => {
  const response = await api.put(`/products/${id}`, productData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteProduct = async (id) => {
  const response = await api.delete(`/products/${id}`);
  return response.data;
};

export const updateStock = async (id, stockData) => {
  const response = await api.put(`/products/${id}/stock`, stockData);
  return response.data;
};

export const toggleProductSale = async (id) => {
  const response = await api.put(`/products/${id}/toggle-sale`);
  return response.data;
};

export const getUserList = async (params) => {
  try {
    const response = await api.get('/users', { params });
    return response.data;
  } catch (error) {
    console.error('获取用户列表时出错:', error);
    throw error;
  }
};

// 在文件末尾添加以下函数
export const getOrderList = async (params) => {
  try {
    const response = await api.get('/orders', { params });
    return response.data;
  } catch (error) {
    console.error('获取订单列表时出错:', error);
    throw error;
  }
};

export const getOrderDetails = async (orderId) => {
  try {
    const response = await api.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('获取订单详情时出错:', error);
    throw error;
  }
};

export const editOrder = async (orderId, orderData) => {
  try {
    const response = await api.put(`/orders/${orderId}`, orderData);
    return response.data;
  } catch (error) {
    console.error('编辑订单时出错:', error);
    throw error;
  }
};
export const confirmOrder = async (orderId) => {
  try {
    const response = await api.put(`/orders/${orderId}/confirm`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const shipOrder = async (orderId) => {
  try {
    const response = await api.put(`/orders/${orderId}/ship`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const readyForPickup = async (orderId) => {
  try {
    const response = await api.put(`/orders/${orderId}/ready-for-pickup`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const completeOrder = async (orderId) => {
  try {
    const response = await api.put(`/orders/${orderId}/complete`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const cancelOrder = async (orderId) => {
  try {
    const response = await api.put(`/orders/${orderId}/cancel`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const bulkCreateProducts = async (formData) => {
    try {
        const response = await api.post('/bulk-create-products', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('批量创建产品时出错:', error);
        throw error;
    }
};