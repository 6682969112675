import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity, SafeAreaView, TouchableWithoutFeedback } from "react-native";
import { useGlobalContext } from "../GlobalContext/GlobalContext";
import SideMenu from './SideMenu';
import WebBackMain from './WebBackMain';
import AdminManager from './AdminManager';
import WebUserManager from './WebUserManager';
import ProductManager from './ProductManager';
import CategoryManager from './CategoryManager';
import { Ionicons } from '@expo/vector-icons';
import OrderManager from './OrderManager';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export default function WAdminMain({ navigation }) {
    const { user } = useGlobalContext();
    const [SelectedComponent, setSelectedComponent] = useState(null);
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(!isMobile);

    let tmpChildren = user && user.role === "admin" 
        ? [
            { title: "管理员列表", icon: "people", component: AdminManager },
            { title: "用户列表", icon: "person", component: WebUserManager },
          ]
        : [
            { title: "用户列表", icon: "person", component: WebUserManager },
          ];

    const menuItems = [
        {
            title: "首页",
            icon: "home",
            children: [],
            component: WebBackMain,
        },
        {
            title: "用户中心",
            icon: "people",
            children: tmpChildren,
        },
        {
            title: "产品管理",
            icon: "cube",
            children: [
                { title: "产品列表", icon: "list", component: ProductManager },
                { title: "分类列表", icon: "grid", component: CategoryManager },
            ],
        },
        {
            title: "订单管理",
            icon: "list",
            children: [
                { title: "订单列表", icon: "document-text", component: OrderManager },
            ],
        },
    ];

    useEffect(() => {
        if (!SelectedComponent) {
            const orderListComponent = menuItems[3].children[0].component;
            setSelectedComponent(() => orderListComponent);
        }
    }, [SelectedComponent]);

    const toggleSideMenu = () => {
        setIsSideMenuVisible(!isSideMenuVisible);
    };

    const closeSideMenu = () => {
        if (isMobile) {
            setIsSideMenuVisible(false);
        }
    };

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.content}>
                {isMobile && (
                    <TouchableOpacity style={styles.menuButton} onPress={toggleSideMenu}>
                        <Ionicons name="menu" size={24} color="#108693" />
                    </TouchableOpacity>
                )}
                {(isSideMenuVisible || !isMobile) && (
                    <SideMenu 
                        menuItems={menuItems} 
                        onMenuItemSelect={(menuItem) => {
                            setSelectedComponent(() => menuItem.component);
                            if (isMobile) setIsSideMenuVisible(false);
                        }} 
                        defaultSelectedItem={menuItems[3].children[0]}
                        isMobile={isMobile}
                    />
                )}
                <TouchableWithoutFeedback onPress={closeSideMenu}>
                    <View style={styles.componentContainer}>
                        {SelectedComponent && <SelectedComponent />}
                    </View>
                </TouchableWithoutFeedback>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F5F5F5',
    },
    content: {
        flex: 1,
        flexDirection: isMobile ? 'column' : 'row',
    },
    componentContainer: {
        flex: 1,
        backgroundColor: "#FFF",
    },
    menuButton: {
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 2,
        padding: 10,
    },
});