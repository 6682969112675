import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, ScrollView, Animated } from "react-native";
import { Ionicons } from '@expo/vector-icons';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

const SideMenu = ({ menuItems, onMenuItemSelect, defaultSelectedItem, isMobile }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [expandedMenu, setExpandedMenu] = useState(null);
    const [slideAnim] = useState(new Animated.Value(isMobile ? -200 : 0));

    useEffect(() => {
        Animated.timing(slideAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }, []);

    const handlePress = (menuIndex, itemIndex) => {
        const newSelectedItem = { menuIndex, itemIndex };
        setSelectedItem(newSelectedItem);
        if (menuItems[menuIndex].children.length === 0) {
            setExpandedMenu(null);
            onMenuItemSelect(menuItems[menuIndex]);
        } else {
            setExpandedMenu(menuIndex);
            onMenuItemSelect(menuItems[menuIndex].children[itemIndex]);
        }
    };

    const handleExpand = (menuIndex) => {
        setExpandedMenu(expandedMenu === menuIndex ? null : menuIndex);
    };

    return (
        <Animated.View style={[
            styles.container, 
            isMobile && styles.mobileContainer,
            { left: slideAnim }
        ]}>
            <ScrollView>
                {menuItems.map((menuItem, index) => (
                    <ExpandableMenu
                        key={menuItem.title}
                        title={menuItem.title}
                        icon={menuItem.icon}
                        selectedMenu={selectedItem?.menuIndex === index}
                        onItemPress={(itemIndex) => handlePress(index, itemIndex)}
                        selectedItem={selectedItem}
                        expanded={expandedMenu === index}
                        onExpand={() => handleExpand(index)}
                        isSelected={selectedItem?.menuIndex === index && menuItem.children.length === 0}
                        isMobile={isMobile}
                    >
                        {menuItem.children}
                    </ExpandableMenu>
                ))}
            </ScrollView>
        </Animated.View>
    );
};

const ExpandableMenu = ({ title, icon, children, selectedMenu, onItemPress, selectedItem, expanded, onExpand, isSelected, isMobile }) => {
    const toggleExpand = () => {
        if (children.length > 0) {
            onExpand();
        } else {
            onItemPress(0);
        }
    };

    return (
        <View style={[styles.menuItem, isSelected && styles.selectedMenuItem]}>
            <TouchableOpacity onPress={toggleExpand} style={styles.menuItemContent}>
                <Ionicons name={icon} size={24} color={isSelected ? "#1886A8" : "#fff"} />
                <Text style={[styles.menuItemText, isSelected && styles.selectedMenuItemText]}>{title}</Text>
                {children.length > 0 && (
                    <Ionicons 
                        name={expanded ? "chevron-down" : "chevron-forward"} 
                        size={24} 
                        color="#fff" 
                        style={styles.expandIcon}
                    />
                )}
            </TouchableOpacity>
            {expanded && (
                <View style={styles.submenu}>
                    {children.map((child, index) => (
                        <ExpandableMenuItem
                            key={index}
                            title={child.title}
                            icon={child.icon}
                            selected={selectedMenu && index === selectedItem?.itemIndex}
                            onPress={() => onItemPress(index)}
                        />
                    ))}
                </View>
            )}
        </View>
    );
};

const ExpandableMenuItem = ({ title, icon, selected, onPress }) => {
    return (
        <TouchableOpacity onPress={onPress} style={[styles.submenuItem, selected && styles.selectedSubmenuItem]}>
            <Ionicons name={icon} size={20} color={selected ? "#1886A8" : "#fff"} />
            <Text style={[styles.submenuItemText, selected && styles.selectedSubmenuItemText]}>{title}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 200,  // 调整电脑版菜单宽度
        height: "100%",
        backgroundColor: "#108693",
        paddingTop: 20,
    },
    mobileContainer: {
        position: 'absolute',
        zIndex: 1,
        width: '80%',
        maxWidth: 300,
    },
    menuItem: {
        marginBottom: 10,
    },
    selectedMenuItem: {
        backgroundColor: '#fff',
    },
    menuItemContent: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    menuItemText: {
        marginLeft: 10,
        color: '#fff',
        fontSize: isMobile ? 18 : 16,
    },
    selectedMenuItemText: {
        color: '#1886A8',
    },
    expandIcon: {
        position: 'absolute',
        right: 10,
    },
    submenu: {
        marginLeft: 20,
    },
    submenuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    selectedSubmenuItem: {
        backgroundColor: '#fff',
    },
    submenuItemText: {
        marginLeft: 10,
        color: '#fff',
        fontSize: isMobile ? 16 : 14,
    },
    selectedSubmenuItemText: {
        color: '#1886A8',
    },
});

export default SideMenu;