export const DevelopmentFirbaseConfig = {
	apiKey: "AIzaSyAavvsky4mUmQiOGhyvA6qTdGa2kALTmXA",
	authDomain: "gift-chat-1cd3b.firebaseapp.com",
	databaseURL: "https://gift-chat-1cd3b-default-rtdb.firebaseio.com",
	projectId: "gift-chat-1cd3b",
	storageBucket: "gift-chat-1cd3b.appspot.com",
	messagingSenderId: "453000969511",
	appId: "1:453000969511:web:3a1a6051261eb9d6043d02",
	measurementId: "G-2SXWP3BGJN",
};

export const ProductionFirbaseConfig = {
	apiKey: "AIzaSyA2tdbIwVqHXeXrz9FRUM3RhUb3IMpFCW4",
	authDomain: "sandal-app-ddc21.firebaseapp.com",
	databaseURL: "https://sandal-app-ddc21-default-rtdb.firebaseio.com",
	projectId: "sandal-app-ddc21",
	storageBucket: "sandal-app-ddc21.appspot.com",
	messagingSenderId: "1011146452454",
	appId: "1:1011146452454:web:49eccb0ec6cb8ec4a9fa27",
	measurementId: "G-90THS0P3FZ",
};

export const DevelopmentStripKey =
	"pk_test_51OWfHCFz8wPWd8gmVdfD5GXCcD3mk7KbsNzirFWauQBwdLml5LSZr1QYSaYssgJhzo76UjtckQpkTAVn0aowtFGW00bgfco7ks";

export const ProductionStripKey =
	"pk_live_51OWfHCFz8wPWd8gmwaryrsZDclYWgIdS2bGzmMPM0dLUJCAefuPJXAMIpnJk9SE25eHND8HwUSM16InJTVVykKCC00QSeyzxN6";
