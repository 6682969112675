import { StyleSheet, View, Platform, StatusBar, Text } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useFonts } from "expo-font";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { GlobalContextProvider } from "./src/GlobalContext/GlobalContext";
import { COLORS } from "./src/CommonStyles/Styles";
import Common from "./src/CommonStyles/common";
import {
	DevelopmentFirbaseConfig,
	ProductionFirbaseConfig,
} from "./src/Util/FirebaseAndStripeConfig";
import { initializeApp } from "firebase/app";

import WebHeader from "./src/Web/WebHeader";
import WebFooter from "./src/Web/WebFooter";
import WebHome from "./src/Web/WebHome";

import WHeader from "./src/Web/WHeader";
import WAdminMain from "./src/Web/WAdminMain";
import WLogin from "./src/Web/WLogin";

const Stack = createStackNavigator();

// Your web app's Firebase configuration
// const firebaseConfig =
const firebaseConfig = __DEV__
	? DevelopmentFirbaseConfig
	: ProductionFirbaseConfig;

// Initialize Firebase
initializeApp(firebaseConfig);

export default function App() {

	return (
		<GlobalContextProvider>
			
			{Platform.OS === "web" ? (
				<View style={{ flex: 1 }}>
					{/* <WebHeader /> */}
					<NavigationContainer>
						<Stack.Navigator initialRouteName="WLogin">
							<Stack.Screen
								name="WebHome"
								component={WebHome}
								options={{ header: () => <WebHeader /> }}
							/>
							<Stack.Screen
								name="WLogin"
								component={WLogin}
								options={{ header: () => null }}
							/>
							<Stack.Screen
								name="WAdminMain"
								component={WAdminMain}
								options={{ header: () => <WHeader /> }}
							/>
						</Stack.Navigator>
					</NavigationContainer>
					{/* <WebFooter /> */}
				</View>
			) : null
			}
			<Toast position="top" />
		</GlobalContextProvider>
	);
}
