import React, { useEffect, useState } from 'react';
import { View, Alert, Image, TextInput, ActivityIndicator, Modal, TouchableOpacity, FlatList, Text, StyleSheet } from "react-native";
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";
import API from "../API/API";
import { Pagination, BanModal, CustomTextInput, CustomText1, RelativeTime, CustomTitle1, CustomTitle, CustomText, CustomButton, CustomButton1, SearchBarUser, RelativeTimeString, WebModal } from "./WebComponent"
import WebAlert from './Alert'
import { getUserList, } from '../Util/api';

const pageSize = 10
const date = new Date();
date.setMonth(date.getMonth() - 3);
const threeMonthsAgo = date.toISOString().slice(0, 10);

export default function WebUserManager() {
    const { user, jwt, setUser, setJwt, role, setRole, } = useGlobalContext();
    const [cpage, setCPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchData, setSearchData] = useState({ code: "", suspend: 2, startDate: threeMonthsAgo, endDate: new Date().toISOString().slice(0, 10) });

    const [addModal, setAddModal] = useState(false)

    const [addUserModal, setAddUserModal] = useState(false)

    const [viewUserModal, setViewUserModal] = useState(false)
    const [userData, setUserData] = useState(null)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [nickName, setNickName] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [province, setProvince] = useState("")
    const [postCode, setPostCode] = useState("")

    const [banModal, setBanModal] = useState(false)
    const [reason, setReason] = useState("")
    useEffect(() => {
        async function fetchData() {
            const response = await getUserList({ page: cpage, limit: pageSize, search: searchData.email })
            console.log(response)
            setData(response.users)

            setTotalItems(response.totalUsers)
            setTotalPages(response.totalPages)
        }
        fetchData();
    }, [])

    const handlePageChange = async (page) => {
        const response = await getUserList({ page: page, limit: pageSize, search: searchData.email })
        console.log(response)
        setData(response.users)
    };


    const itemOnPress = (data) => {
        console.log(data)
    }


    const deleteUserOnPress = (data) => {
        console.log(data)
        WebAlert(
            "",
            "Please confirm whether to delete the user?",
            [
                {
                    text: "Cancel",
                    onPress: () => console.log("取消删除"),
                    style: "cancel"
                },
                {
                    text: "Confirm",
                    onPress: () => {
                        console.log("确认删除");
                        // 在这里添加你的删除用户的代码

                        API.deleteUser(jwt, {
                            id: data.id,
                        }, (data) => {
                            handlePageChange(1)
                        }, (error) => {
                            console.log(error)
                            alert(error.data.msg)
                        });
                    }
                }
            ],
            { cancelable: false }
        );

    }

    return (
        <View style={styles.container}>


            <SearchBarUser
                searchData={searchData}
                placeholder={'Please enter the user ID'}
                status={'Choose'}
                onPress={() => {
                    setAddModal(true)
                }}
                onSearch={(searchTerm, startDate, endDate, status, suspend, email, phone) => {
                    console.log(searchTerm, startDate, endDate, status, suspend, email, phone)
                    setSearchData({
                        code: searchTerm,
                        startDate: startDate,
                        endDate: endDate,
                        suspend: suspend,
                        email: email,
                        phone: phone
                    })

                    getUserList(1, {
                        code: searchTerm,
                        startDate: startDate,
                        endDate: endDate,
                        suspend: suspend,
                        email: email,
                        phone: phone
                    })
                }}
            />


            <View style={{
                marginLeft: 25 * Common.WIDTH_DIVISOR,
                flexDirection: "row",
                width: 1600 * Common.WIDTH_DIVISOR,
                borderWidth: 1,
                borderColor: "rgba(217, 217, 217, 1.0)",
                height: 50 * Common.WIDTH_DIVISOR,
                marginTop: 10,
            }}>
                <CustomTitle title="Name" width="10%" />
                {/* <CustomTitle title="Address" width="20%" /> */}
                {/* <CustomTitle title="Contact Number" width="15%" /> */}
                <CustomTitle title="E-mail" width="15%" />
                <CustomTitle title="Register Time" width="15%" />
                {/* <CustomTitle title="Status" width="10%" /> */}
                <CustomTitle title="Operation" width="15%" />
            </View>

            <FlatList
                style={{
                    marginTop: 20,
                    width: 1600 * Common.WIDTH_DIVISOR,
                    marginLeft: 25 * Common.WIDTH_DIVISOR,
                }}
                data={data}
                scrollEnabled={false}
                renderItem={({ item, index }) => {
                    return (
                        <View style={{
                            width: "100%",
                            height: 60 * Common.WIDTH_DIVISOR,
                            flexDirection: "row",
                            borderBottomColor: "rgba(156, 156, 156, 1.0)",
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",

                        }}>
                            <CustomText title={item.name} width="10%" />
                            <CustomText title={item.email} width="15%" />
                            <CustomText title={RelativeTimeString(item.createdAt)} width="15%" />
                            <View style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "15%"
                            }}>
                                <CustomButton title={"Review"} onPress={() => {
                                    console.log("item:", item)
                                    // API.getReferUser(jwt, { userId: item.id }, (data) => {
                                    //     console.log(data)
                                    //     if (data) {
                                    //         setUserData({
                                    //             ...item,
                                    //             referUser: data
                                    //         })
                                    //     }
                                    // })
                                    // setUserData(item)
                                    // setViewUserModal(true)
                                }} />

                                <CustomButton title={"Delete"} onPress={() => { deleteUserOnPress(item) }} style={{ marginLeft: 10 * Common.WIDTH_DIVISOR }} />
                            </View>
                        </View>
                    )
                }}
            />

            < Pagination
                cpage={cpage}
                totalItems={totalItems}
                totalPage={totalPages}
                itemsPerPage={pageSize}
                onPageChange={handlePageChange}
            />



            <Modal
                transparent={true}
                visible={addUserModal}
            >
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}>
                    <View style={{
                        marginTop: 100 * Common.WIDTH_DIVISOR,
                        width: 894 * Common.WIDTH_DIVISOR,
                        height: 700 * Common.WIDTH_DIVISOR,
                        borderRadius: 4,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1

                    }}>
                        <Image style={{
                            marginTop: 24 * Common.WIDTH_DIVISOR,
                            width: 82 * Common.WIDTH_DIVISOR,
                            height: 82 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }} source={require("../../resource/Web/people-fill.png")} />

                        <Text style={{
                            marginTop: 8 * Common.WIDTH_DIVISOR,
                            fontSize: 18 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#000000"
                        }}>Register / Edit Profile</Text>

                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Image style={{
                                marginLeft: 60 * Common.WIDTH_DIVISOR,
                                width: 30 * Common.WIDTH_DIVISOR,
                                height: 30 * Common.WIDTH_DIVISOR,
                                alignSelf: "center",
                            }} source={require("../../resource/Web/people-fill.png")} />

                            <Text style={{
                                marginLeft: 12 * Common.WIDTH_DIVISOR,
                                fontSize: 18 * Common.WIDTH_DIVISOR,
                                fontWeight: "400",
                                textAlign: "center",
                                color: "#108693"
                            }}>User</Text>
                        </View>

                        <View style={{
                            marginTop: 6 * Common.WIDTH_DIVISOR,
                            width: 803 * Common.WIDTH_DIVISOR,
                            height: 1,
                            backgroundColor: "rgba(24, 134, 168, 1.0)",
                            alignSelf: "center",
                        }}></View>

                        <View style={{
                            marginTop: 28 * Common.WIDTH_DIVISOR,
                            flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                            height: 80 * Common.WIDTH_DIVISOR
                        }}>
                            <CustomTextInput
                                title={"Email"}
                                placeholder={"Please enter email"}
                                onChangeText={(text) => {
                                    setEmail(text)
                                }}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />
                            <CustomTextInput title={"Password"}
                                placeholder={"Please enter password"}
                                onChangeText={(text) => {
                                    setPassword(text)
                                }}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />
                        </View>
                        <View style={{
                            marginTop: 28 * Common.WIDTH_DIVISOR,
                            flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                            height: 80 * Common.WIDTH_DIVISOR
                        }}>
                            <CustomTextInput
                                title={"Nick Name"}
                                placeholder={"Please enter nick name"}
                                onChangeText={(text) => {
                                    setNickName(text)
                                }}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />

                            <CustomTextInput title={"Address"}
                                placeholder={"Please enter address"}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                                onChangeText={(text) => {
                                    setAddress(text)
                                }}
                            />
                        </View>

                        <View style={{
                            marginTop: 28 * Common.WIDTH_DIVISOR,
                            flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                            height: 80 * Common.WIDTH_DIVISOR
                        }}>
                            <CustomTextInput
                                title={"City"}
                                placeholder={"Please enter city"}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                                onChangeText={(text) => {
                                    setCity(text)
                                }}
                            />
                            <CustomTextInput title={"Province"}
                                placeholder={"Please enter province"}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                                onChangeText={(text) => {
                                    setProvince(text)
                                }}
                            />
                        </View>

                        <View style={{
                            marginTop: 28 * Common.WIDTH_DIVISOR,
                            flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                            height: 80 * Common.WIDTH_DIVISOR
                        }}>
                            <CustomTextInput
                                title={"Post Code"}
                                placeholder={"Please enter post code"}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                                onChangeText={(text) => {
                                    setPostCode(text)
                                }}
                            />

                        </View>


                        <View style={{
                            flexDirection: "row",
                            marginTop: 40 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }}>


                            <CustomButton
                                style={{
                                    marginLeft: 20 * Common.WIDTH_DIVISOR
                                }}
                                title={"Back"}
                                onPress={() => {
                                    setAddUserModal(false)
                                    setAddModal(true)
                                }} />
                        </View>

                    </View>
                </View>
            </Modal>

            <WebModal
                visible={viewUserModal}
                onClose={() => setViewUserModal(false)}>
                <View style={{
                    marginTop: 100 * Common.WIDTH_DIVISOR,
                    width: 894 * Common.WIDTH_DIVISOR,
                    borderRadius: 4,
                    backgroundColor: "#FFFFFF",
                    shadowColor: "rgba(0, 0, 0, 0.25)",
                    shadowOffset: {
                        width: 0,
                        height: 4
                    },
                    shadowRadius: 4,
                    shadowOpacity: 1,
                    alignSelf: 'center',

                }}>
                    <Image style={{
                        marginTop: 24 * Common.WIDTH_DIVISOR,
                        width: 82 * Common.WIDTH_DIVISOR,
                        height: 82 * Common.WIDTH_DIVISOR,
                        alignSelf: "center",
                    }} source={require("../../resource/Web/people-fill.png")} />

                    <Text style={{
                        marginTop: 8 * Common.WIDTH_DIVISOR,
                        fontSize: 18 * Common.WIDTH_DIVISOR,
                        fontWeight: "400",
                        textAlign: "center",
                        color: "#000000"
                    }}>Porfile</Text>

                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image style={{
                            marginLeft: 60 * Common.WIDTH_DIVISOR,
                            width: 30 * Common.WIDTH_DIVISOR,
                            height: 30 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }} source={require("../../resource/Web/people-fill.png")} />

                        <Text style={{
                            marginLeft: 12 * Common.WIDTH_DIVISOR,
                            fontSize: 18 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#108693"
                        }}>User</Text>
                    </View>

                    <View style={{
                        marginTop: 6 * Common.WIDTH_DIVISOR,
                        width: 803 * Common.WIDTH_DIVISOR,
                        height: 1,
                        backgroundColor: "rgba(24, 134, 168, 1.0)",
                        alignSelf: "center",
                    }}></View>

                    <View style={{
                        marginTop: 28 * Common.WIDTH_DIVISOR,
                        flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                        height: 80 * Common.WIDTH_DIVISOR
                    }}>
                        <CustomTextInput
                            title={"Email"}
                            placeholder={"请输入Email"}
                            onChangeText={(text) => {
                                console.log(text)
                            }}
                            editable={false}
                            value={userData ? userData.email : ""}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                        />
                        <CustomTextInput
                            title={"Nick Name"}
                            placeholder={"Please enter nick name"}
                            editable={false}
                            value={userData ? userData.nickName : ""}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                        />
                    </View>
                    <View style={{
                        marginTop: 28 * Common.WIDTH_DIVISOR,
                        flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                        height: 80 * Common.WIDTH_DIVISOR
                    }}>
                        <CustomTextInput title={"Address"}
                            placeholder={"Please enter address"}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            editable={false}
                            value={userData ? `${userData.address.address} ${userData.address.city} ${userData.address.province} ${userData.address.postCode}` : ""}
                        />
                        <CustomTextInput
                            title={"Phone Number"}
                            placeholder={""}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            editable={false}
                            value={userData ? userData.phone : ""}
                        />
                    </View>
                    <View style={{
                        marginTop: 28 * Common.WIDTH_DIVISOR,
                        flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                        height: 80 * Common.WIDTH_DIVISOR
                    }}>
                        <CustomTextInput
                            title={"Register Time"}
                            placeholder={""}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            editable={false}
                            value={userData ? userData.createdAt : ""}
                        />
                    </View>

                    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 * Common.WIDTH_DIVISOR, }}>
                        <Image style={{
                            marginLeft: 60 * Common.WIDTH_DIVISOR,
                            width: 30 * Common.WIDTH_DIVISOR,
                            height: 30 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }} source={require("../../resource/Web/people-fill.png")} />

                        <Text style={{
                            marginLeft: 12 * Common.WIDTH_DIVISOR,
                            fontSize: 18 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#108693"
                        }}>Recommender</Text>
                    </View>

                    <View style={{
                        marginTop: 6 * Common.WIDTH_DIVISOR,
                        width: 803 * Common.WIDTH_DIVISOR,
                        height: 1,
                        backgroundColor: "rgba(24, 134, 168, 1.0)",
                        alignSelf: "center",
                    }}></View>

                    <View style={{
                        marginTop: 28 * Common.WIDTH_DIVISOR,
                        flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                        height: 80 * Common.WIDTH_DIVISOR
                    }}>
                        <CustomTextInput title={"Nick Name"}
                            placeholder={"Please enter address"}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            editable={false}
                            value={userData && userData.referUser ? userData.referUser.nickName : "N/A"}
                        />
                        <CustomTextInput
                            title={"E-Mail"}
                            placeholder={""}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            editable={false}
                            value={userData && userData.referUser ? userData.referUser.email : "N/A"}
                        />
                    </View>
                    <View style={{
                        marginTop: 28 * Common.WIDTH_DIVISOR,
                        flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                        height: 80 * Common.WIDTH_DIVISOR
                    }}>
                        <CustomTextInput title={"Phone"}
                            placeholder={"Please enter address"}
                            style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            editable={false}
                            value={userData && userData.referUser ? userData.referUser.phone : "N/A"}
                        />

                    </View>

                    <View style={{
                        flexDirection: "row",
                        marginVertical: 40 * Common.WIDTH_DIVISOR,
                        alignSelf: "center"
                    }}>
                        {/* <CustomButton1
                                style={{

                                }}
                                title={"Confirm"}
                                onPress={()=>{
                                    setViewUserModal(false)
                                }} /> */}

                        <CustomButton
                            style={{
                                marginLeft: 20 * Common.WIDTH_DIVISOR
                            }}
                            title={"Back"}
                            onPress={() => {
                                setViewUserModal(false)
                            }} />
                    </View>

                </View>
            </WebModal>
            <BanModal
                banModal={banModal}
                setBanModal={setBanModal}
                reason={reason}
                setReason={setReason}
                onSubmit={() => {
                    API.updateUserSuspend(jwt, {
                        id: userData.id,
                        isSuspend: 1,
                        suspendRemark: reason
                    }, (data) => {
                        setBanModal(false)
                        setData(prevData => prevData.map(item =>
                            item.id === userData.id ? { ...item, isSuspend: 1 } : item
                        ));
                    });
                }} />
        </View >
    );
}




const styles = StyleSheet.create({
    container: {
        backgroundColor: "#fff",
        width: "100%"
    },
    title: {
        fontSize: 16 * Common.WIDTH_DIVISOR,
        fontWeight: "400",
        fontStyle: "normal",
        textAlign: "center",
        color: "#1886A8"
    },
    des: {
        fontSize: 16 * Common.WIDTH_DIVISOR,
        fontWeight: "400",
        fontStyle: "normal",
        lineHeight: 16,
        textAlign: "center",
        color: "#000000"
    },
    frameChildLayout: {
        height: 45,
    },
    goTypo: {
        textAlign: "left",
        fontFamily: "Inter-Regular",
    },
    frameChild: {
        top: 0,
        left: 514,
        backgroundColor: "#108693",
        width: 88
    },
    go: {
        marginTop: 7,
        left: 541,
        fontSize: 25,
        color: "#fff"
    },
    text3: {
        top: 13,
        left: 55,
        fontSize: 15,
        color: "#8b8b8b"
    },
    rectangleParent: {
        borderRadius: 100,
        borderStyle: "solid",
        borderColor: "#d2d2d2",
        borderWidth: 1,
        width: 602,
        height: 45,
        overflow: "hidden"
    },
    text4: {
        top: 210,
        fontSize: 20,
        width: 584,
        fontFamily: "Inter-Regular",
        color: "#656464"
    },
    text5: {
        top: 144,
        fontSize: 45,
        fontWeight: "600",
        fontFamily: "Inter-SemiBold",
        width: 514,
        height: 57
    },
    groupIcon: {
        height: "59.63%",
        width: "18.75%",
        top: "49.89%",
        right: "26.67%",
        bottom: "-9.52%",
        left: "54.58%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden"
    },
    frameParent: {
        backgroundColor: "#fff",
        flex: 1,
        width: "100%",
        height: 465,
        overflow: "hidden"
    }
});