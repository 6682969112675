import React, { useEffect } from 'react';
import { View, Image, Text, StyleSheet } from "react-native";
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";

export default function WebHome({ navigation }) {
    const { user, setUser, setJwt, role, setRole, } = useGlobalContext();

    useEffect(() => {
        console.log(user)
        if (user) {
            if (role == "admin") {
                navigation.navigate('WAdminMain')
            } else {
                navigation.navigate('WAdminMain')
            }
        }
    }, [user])

    return (
        <View style={styles.container}>
            <View style={{
                backgroundColor: "#FFF",
                width: "100%",
                height: 400,
            }}>
                <Text style={{
                    color: "#656464",
                    fontSize: 45,
                    fontWeight: "600",
                    marginTop: 70,
                    marginLeft: 446 * Common.WIDTH_DIVISOR,
                }}>随时随地，预约靠谱师傅</Text>

                <Text style={{
                    color: "#656464",
                    fontSize: 20,
                    fontWeight: "400",
                    marginTop: 10,
                    marginLeft: 446 * Common.WIDTH_DIVISOR,
                }}>快速获取多个报价，无需逐个电话询问</Text>

                <View style={{
                    flexDirection: "row",
                    marginLeft: 446 * Common.WIDTH_DIVISOR,
                    marginTop: 8,
                    alignItems: "center"
                }}>
                    <View style={{
                        borderRadius: 100,
                        borderStyle: "solid",
                        borderColor: "#d2d2d2",
                        borderWidth: 1,
                        width: 570,
                        marginLeft: 0,
                        height: 45,
                        overflow: "hidden",
                        height: 45,
                        flexDirection: "row",
                        alignItems: "center",
                    }
                    }>
                        <Image style={{
                            marginLeft: 10,
                            width: 16,
                            height: 16
                        }} source={require("../../resource/Web/search.png")} />
                        <Text style={{
                            marginLeft: 10,
                            fontSize: 15,
                            width: 300,
                            color: "#8b8b8b",
                        }}>请问你需要什么维修安装服务？</Text>
                        <View style={
                            {
                                marginLeft: 230,
                                backgroundColor: "#108693",
                                width: 88,
                                height: 45,
                                justifyContent: "center",
                                alignItems: "center",
                            }
                        } >
                            <Text style={{
                                fontSize: 25,
                                color: "#fff",
                                textAlign: "left",
                            }}>Go
                            </Text>
                        </View>
                    </View>
                    <Image style={{
                        width: 360,
                        height: 233
                    }} source={require("../../resource/Web/main1.png")} />);


                </View>


            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#F5F8FA",
        width: "100%"
    },
    frameChildLayout: {
        height: 45,
    },
    goTypo: {
        textAlign: "left",
        fontFamily: "Inter-Regular",
    },
    frameChild: {
        top: 0,
        left: 514,
        backgroundColor: "#108693",
        width: 88
    },
    go: {
        marginTop: 7,
        left: 541,
        fontSize: 25,
        color: "#fff"
    },
    text3: {
        top: 13,
        left: 55,
        fontSize: 15,
        color: "#8b8b8b"
    },
    rectangleParent: {
        borderRadius: 100,
        borderStyle: "solid",
        borderColor: "#d2d2d2",
        borderWidth: 1,
        width: 602,
        height: 45,
        overflow: "hidden"
    },
    text4: {
        top: 210,
        fontSize: 20,
        width: 584,
        fontFamily: "Inter-Regular",
        color: "#656464"
    },
    text5: {
        top: 144,
        fontSize: 45,
        fontWeight: "600",
        fontFamily: "Inter-SemiBold",
        width: 514,
        height: 57
    },
    groupIcon: {
        height: "59.63%",
        width: "18.75%",
        top: "49.89%",
        right: "26.67%",
        bottom: "-9.52%",
        left: "54.58%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden"
    },
    frameParent: {
        backgroundColor: "#fff",
        flex: 1,
        width: "100%",
        height: 465,
        overflow: "hidden"
    }
});