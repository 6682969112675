import React, { useEffect, useState } from 'react';
import { View, Button, Text, TextInput, Image, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import Common from '../CommonStyles/common';
import { sendVerificationCode, login } from '../Util/api';
import { useGlobalContext } from "../GlobalContext/GlobalContext";

export default function WLogin({ navigation }) {
    const { user, setUser, setJwt } = useGlobalContext();
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        if (user) {
            let user_ = user;
            if (user.roleList && user.roleList.length > 0) {
                user_.role = user.roleList[0].name;
                user_.role = "system_user";
                setUser(user_);
            }
            if (user_.role === "admin" || user_.role === "system_user") {
                navigation.navigate('WAdminMain');
            }
        }
    }, [user]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const handleSendVerificationCode = async () => {
        if (!email) {
            alert("Email is required");
            return;
        }
        try {
            await sendVerificationCode(email);
            setCountdown(60);
            alert("Verification code sent successfully");
        } catch (error) {
            alert("Failed to send verification code");
        }
    };

    const handleLogin = async () => {
        if (!email || !verificationCode) {
            alert("All fields are required");
            return;
        }
        try {
            const response = await login(email, verificationCode);
            if (response.user && response.token) {
                let user = response.user;
                if (response.roleList && response.roleList.length > 0) {
                    user.role = response.roleList[0].name;
                }
                user.role = "system_user";
                if (user.role === "admin" || user.role === "system_user") {
                    setUser(user);
                    setJwt(response.token);
                    alert("Login successful");
                    navigation.navigate('WAdminMain');
                } else {
                    alert("You don't have permission to login here. Please login from the correct platform.");
                }
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            alert("Login failed");
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.loginCard}>
                <Image source={require("../../resource/Web/webicon.png")}
                    style={styles.logo} />
                <TextInput
                    placeholder="邮箱"
                    style={styles.input}
                    onChangeText={(text) => setEmail(text)}
                />
                <View style={styles.verificationCodeContainer}>
                    <TextInput
                        placeholder="验证码"
                        style={[styles.input, styles.verificationCodeInput]}
                        onChangeText={(text) => setVerificationCode(text)}
                    />
                    <TouchableOpacity
                        style={[styles.button, styles.sendCodeButton]}
                        onPress={handleSendVerificationCode}
                        disabled={countdown > 0}
                    >
                        <Text style={styles.buttonText}>
                            {countdown > 0 ? `${countdown}秒` : "发送验证码"}
                        </Text>
                    </TouchableOpacity>
                </View>
                <TouchableOpacity style={styles.loginButton} onPress={handleLogin}>
                    <Text style={styles.buttonText}>登录</Text>
                </TouchableOpacity>
            </View>
            <Text style={styles.footer}>© 2014-2024, IDI I-tech 版权所有</Text>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: '#108693',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    loginCard: {
        backgroundColor: '#ffffff',
        alignItems: 'center',
        borderRadius: 10,
        padding: 20,
        width: '100%',
        maxWidth: 400,
    },
    logo: {
        width: 200,
        height: 93,
        resizeMode: 'contain',
        marginBottom: 30,
    },
    input: {
        height: 50,
        borderColor: '#1886a8',
        borderWidth: 1,
        width: '100%',
        marginBottom: 15,
        paddingLeft: 10,
        fontSize: 16,
        fontFamily: "Inter-Regular",
        color: "#9c9c9c",
        borderRadius: 4,
    },
    checkboxContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    checkboxLabel: {
        marginLeft: 8,
    },
    button: {
        borderRadius: 4,
        backgroundColor: "#108693",
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
    },
    buttonText: {
        color: 'white',
        textAlign: 'center',
        fontSize: 16,
        fontFamily: "Inter-Regular",
    },
    forgotPassword: {
        marginTop: 10,
        color: '#00796b',
    },
    footer: {
        marginTop: 20,
        color: 'white',
        fontSize: 12,
    },
    verificationCodeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
        width: '100%',
    },
    verificationCodeInput: {
        flex: 1,
        marginRight: 10,
    },
    sendCodeButton: {
        width: 120,
    },
    loginButton: {
        borderRadius: 4,
        backgroundColor: "#108693",
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        width: '100%',
    },
});