import React, { useEffect } from 'react';
import { View, Image, Text, StyleSheet } from "react-native";
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";

export default function WebBackMain() {
    const { user, setUser, setJwt, role, setRole, } = useGlobalContext();

    return (
        <View style={styles.container}>
            {/* <View style={{
                width: "100%",
                height: 65 * Common.WIDTH_DIVISOR,
                borderRadius: 4,
                backgroundColor: "#FFFFFF",
                shadowColor: "rgba(0, 0, 0, 0.25)",
                shadowOffset: {
                    width: 0,
                    height: 4
                },
                shadowRadius: 4,
                shadowOpacity: 1,
                marginTop: 8 * Common.WIDTH_DIVISOR,
                marginLeft: 8 * Common.WIDTH_DIVISOR,
                flexDirection: "row",
                alignItems: "center",
            }}>

                <Text style={{
                    width: 144,
                    height: 19,
                    marginLeft: 27 * Common.WIDTH_DIVISOR,
                    fontFamily: "Inter",
                    fontSize: 16 * Common.WIDTH_DIVISOR,
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: 16,
                    color: "#000000"
                }}>
                    hello, Chris 管理员
                </Text>

                <Image style={{
                    width: 24 * Common.WIDTH_DIVISOR,
                    height: 24 * Common.WIDTH_DIVISOR,
                    marginLeft: 1352 * Common.WIDTH_DIVISOR,
                }} source={require("../../resource/Web/arrow-repeat.png")} />

                <Text style={{
                    marginLeft: 12 * Common.WIDTH_DIVISOR,
                    fontSize: 16 * Common.WIDTH_DIVISOR,
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "#108693"
                }}>刷新</Text>
            </View> */}

            <View style={{
                marginTop: 8 * Common.WIDTH_DIVISOR,
                flexDirection: "row"
            }}>
                <View style={{
                    width: 254 * Common.WIDTH_DIVISOR,
                    height: 399 * Common.WIDTH_DIVISOR,
                    backgroundColor: "#FFFFFF",
                    shadowColor: "rgba(0, 0, 0, 0.25)",
                    shadowOffset: {
                        width: 0,
                        height: 4
                    },
                    shadowRadius: 4,
                    shadowOpacity: 1,
                    marginLeft: 13 * Common.WIDTH_DIVISOR,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Text style={{
                        height: 17 * Common.WIDTH_DIVISOR,
                        fontFamily: "Inter",
                        fontSize: 14 * Common.WIDTH_DIVISOR,
                        fontWeight: "400",
                        fontStyle: "normal",
                        color: "#000000"
                    }}>
                        Modifiable modules
                    </Text>
                </View>

                <View style={{
                    flexDirection: "column",
                    marginLeft: 8 * Common.WIDTH_DIVISOR,
                }}>
                    <View style={{
                        width: 271 * Common.WIDTH_DIVISOR,
                        height: 195 * Common.WIDTH_DIVISOR,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1,
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontFamily: "Inter",
                            fontSize: 14 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#000000",
                            marginTop: 13 * Common.WIDTH_DIVISOR,

                        }}>
                            Processing work orders
                        </Text>
                        <Image style={{
                            marginTop: 27 * Common.WIDTH_DIVISOR,
                            width: 81 * Common.WIDTH_DIVISOR,
                            height: 81 * Common.WIDTH_DIVISOR,
                        }} source={require("../../resource/Web/journal-text.png")} />
                    </View>
                    <View style={{
                        marginTop: 9 * Common.WIDTH_DIVISOR,
                        width: 271 * Common.WIDTH_DIVISOR,
                        height: 195 * Common.WIDTH_DIVISOR,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1,
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontFamily: "Inter",
                            fontSize: 14 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#000000",
                            marginTop: 21 * Common.WIDTH_DIVISOR,
                        }}>
                            Reviews management
                        </Text>
                        <Image style={{
                            marginTop: 21 * Common.WIDTH_DIVISOR,
                            width: 78 * Common.WIDTH_DIVISOR,
                            height: 78 * Common.WIDTH_DIVISOR,
                        }} source={require("../../resource/Web/chat-text.png")} />
                    </View>
                </View>

                <View style={{
                    flexDirection: "column",
                    marginLeft: 8 * Common.WIDTH_DIVISOR,
                }}>
                    <View style={{
                        width: 271 * Common.WIDTH_DIVISOR,
                        height: 195 * Common.WIDTH_DIVISOR,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1,
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontFamily: "Inter",
                            fontSize: 14 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#000000",
                            marginTop: 13 * Common.WIDTH_DIVISOR,

                        }}>
                            Closed work orders
                        </Text>
                        <Image style={{
                            marginTop: 27 * Common.WIDTH_DIVISOR,
                            width: 81 * Common.WIDTH_DIVISOR,
                            height: 81 * Common.WIDTH_DIVISOR,
                        }} source={require("../../resource/Web/journal-x.png")} />
                    </View>
                    <View style={{
                        marginTop: 9 * Common.WIDTH_DIVISOR,
                        width: 271 * Common.WIDTH_DIVISOR,
                        height: 195 * Common.WIDTH_DIVISOR,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1,
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontFamily: "Inter",
                            fontSize: 14 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            fontStyle: "normal",
                            color: "#000000",
                            marginTop: 21 * Common.WIDTH_DIVISOR,
                        }}>
                            All reviews and notes
                        </Text>
                        <Image style={{
                            marginTop: 21 * Common.WIDTH_DIVISOR,
                            width: 78 * Common.WIDTH_DIVISOR,
                            height: 78 * Common.WIDTH_DIVISOR,
                        }} source={require("../../resource/Web/chat-square-text.png")} />
                    </View>
                </View>

                <Image style={{
                    marginLeft: 8 * Common.WIDTH_DIVISOR,
                    width: 828 * Common.WIDTH_DIVISOR,
                    height: 403 * Common.WIDTH_DIVISOR,
                }} source={require("../../resource/Web/Frame 123.png")} ></Image>
            </View>
            <View style={{
                marginTop: 8 * Common.WIDTH_DIVISOR,
                flexDirection: "row"
            }}>
                <Image style={{
                    marginLeft: 8 * Common.WIDTH_DIVISOR,
                    width: 401 * Common.WIDTH_DIVISOR,
                    height: 599 * Common.WIDTH_DIVISOR,
                }} source={require("../../resource/Web/Frame 140.png")} ></Image>
                <Image style={{
                    marginLeft: 8 * Common.WIDTH_DIVISOR,
                    width: 1243 * Common.WIDTH_DIVISOR,
                    height: 598 * Common.WIDTH_DIVISOR,
                }} source={require("../../resource/Web/Frame 141.png")} ></Image>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#F5F8FA",
        width: "100%"
    },
    frameChildLayout: {
        height: 45,
    },
    goTypo: {
        textAlign: "left",
        fontFamily: "Inter-Regular",
    },
    frameChild: {
        top: 0,
        left: 514,
        backgroundColor: "#108693",
        width: 88
    },
    go: {
        marginTop: 7,
        left: 541,
        fontSize: 25,
        color: "#fff"
    },
    text3: {
        top: 13,
        left: 55,
        fontSize: 15,
        color: "#8b8b8b"
    },
    rectangleParent: {
        borderRadius: 100,
        borderStyle: "solid",
        borderColor: "#d2d2d2",
        borderWidth: 1,
        width: 602,
        height: 45,
        overflow: "hidden"
    },
    text4: {
        top: 210,
        fontSize: 20,
        width: 584,
        fontFamily: "Inter-Regular",
        color: "#656464"
    },
    text5: {
        top: 144,
        fontSize: 45,
        fontWeight: "600",
        fontFamily: "Inter-SemiBold",
        width: 514,
        height: 57
    },
    groupIcon: {
        height: "59.63%",
        width: "18.75%",
        top: "49.89%",
        right: "26.67%",
        bottom: "-9.52%",
        left: "54.58%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden"
    },
    frameParent: {
        backgroundColor: "#fff",
        flex: 1,
        width: "100%",
        height: 465,
        overflow: "hidden"
    }
});