import React from 'react';
import { View, Button, Text, Image, TouchableOpacity } from "react-native";
import { useNavigation } from '@react-navigation/native';
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";

export default function WebHeader() {
    const navigation = useNavigation();
    const { user, role, setUser, setJwt, setRole, } = useGlobalContext();
    return (
        <View style={{
            justifyContent: 'space-between',  // 添加这一行
            flexDirection: 'row',
            width: "100%",
            backgroundColor: "#fff",
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0.1,
            shadowRadius: 8,
            elevation: 5
        }}>
            <TouchableOpacity onPress={() => navigation.navigate('WebHome')}>
                <View style={{
                    flexDirection: 'row',
                    height: 69,
                    alignItems: "center",
                }}>
                    <Image
                        source={require("../../resource/Web/icon.png")}
                        style={{
                            marginLeft: 26 * Common.WIDTH_DIVISOR,
                            height: 52,
                            width: 52,
                        }}
                    />
                    <Image
                        source={require("../../resource/Web/icon1.png")}
                        style={{
                            height: 69,
                            width: 143,
                        }} />
                </View>
            </TouchableOpacity>

            {user ?
                <View style={{
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    height: 69,
                    alignItems: "center",
                }}>
                    <TouchableOpacity onPress={() => {
                        console.log(role)
                        console.log(user)
                        if (role == "admin") {
                            navigation.navigate('WebAdminMain');
                        } else {
                            navigation.navigate('WebMain')
                        }
                    }

                    }
                    >
                        <Text style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#656464",
                            marginRight: 50 * Common.WIDTH_DIVISOR,
                        }}>我的主页</Text>
                    </TouchableOpacity>
                    <Text style={{
                        fontSize: 20,
                        fontWeight: "600",
                        color: "#656464",
                        marginRight: 50 * Common.WIDTH_DIVISOR,
                    }}>Hello! {user.nickName}</Text>
                    <TouchableOpacity onPress={() => {
                        setUser(null);
                        setJwt(null);
                        setRole(null);
                        navigation.navigate('WebHome');
                    }}>
                        <Text style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#656464",
                            marginRight: 50 * Common.WIDTH_DIVISOR,
                        }}>LogOut</Text>
                    </TouchableOpacity>
                </View> :
                <View style={{
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    height: 69,
                    alignItems: "center",
                }}>
                    <TouchableOpacity>
                        <Text style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#656464",
                            marginRight: 50 * Common.WIDTH_DIVISOR,
                        }}>服务项目</Text>
                    </TouchableOpacity>
                    <TouchableOpacity>
                        <Text style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#656464",
                            marginRight: 50 * Common.WIDTH_DIVISOR,
                        }}>师傅/管理处入驻</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {}}>
                        <Text style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#656464",
                            marginRight: 120 * Common.WIDTH_DIVISOR,
                        }}>登录</Text>
                    </TouchableOpacity>
                </View>
            }

        </View>
    );
}