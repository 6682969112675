import axios from "axios";
import { Toast } from "react-native-toast-message/lib/src/Toast";
// export const baseUrl = "https://api.lf888.co.uk/lmback";
export const baseUrl = "http://localhost:3033/lmback";

const API = {
	//注册
	register: (registerData, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/user/register`, registerData, {
				headers: {
					Accept: "application/json",
				},
			})
			.then((res) => {
				// console.log("registerRes", res);
				if (res.data.msg == "success" || res.data.code === 200) {
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("register API Error", res);
					onFail(res.data);
				}
			})
			.catch((error) => {
				console.log("register API Error", error);
				onFail(error);
			});
	},
	// 登录
	login: (loginData, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/user/login`, loginData, {
				headers: {
					Accept: "application/json",
				},
			})
			.then((res) => {
				// console.log("loginRes", res);
				if (res.data.msg == "success" || res.data.code === 200) {
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					console.log("fail", res.data);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("login API Error", res);
					onFail(res.data);
				}
			})
			.catch((error) => {
				console.log("login API Error", error);
				onFail(error);
			});
	},
	//忘记密码
	sendForgetPswEmail: (payload, onSuccess) => {
		axios
			.post(`${baseUrl}/user/forget_password_send_message`, payload, {
				headers: {
					Accept: "application/json",
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("forget_password_send_message API Error1", res);
				}
			})
			.catch((error) =>
				console.log("forget_password_send_message API Error2", error)
			);
	},
	//修改密码
	resetPsw: (payload, onSuccess) => {
		axios
			.post(`${baseUrl}/user/change_password_by_code`, payload, {
				headers: {
					Accept: "application/json",
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("change_password_by_code API Error1", res);
				}
			})
			.catch((error) =>
				console.log("change_password_by_code API Error2", error)
			);
	},
	//发送手机验证码
	sendPhoneCode: (jwt, phoneCodeData) => {
		axios
			.post(`${baseUrl}/user/send_phone_message`, phoneCodeData, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				// console.log("phoneCodeData", res);
			})
			.catch((error) => console.log("phoneCodeData API Error", error));
	},
	// 绑定手机号
	bindPhone: (jwt, phoneCodeData, onSuccess) => {
		axios
			.post(`${baseUrl}/user/user_update_phone`, phoneCodeData, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("update_phone API Error", res);
				}
			})
			.catch((error) => {
				console.log("update_phone API Error", error);
			});
	},
	//获取师傅信息
	getWorkerInfo: (jwt, onSuccess) => {
		axios
			.post(
				`${baseUrl}/worker/select_worker_info`,
				{},
				{
					headers: {
						Accept: "application/json",
						token: jwt,
					},
				}
			)
			.then((res) => {
				if (res.data.msg == "success" || res.data.code === 200) {
					// console.log("select_worker_inf", res.data);
					if (res.data.data) {
						onSuccess(res.data.data);
					}
				} else if (res.data.code === 500) {
					console.log("select_worker_info error", res.data.msg);
				} else {
					console.log("select_worker_info API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_worker_info API Error1", error);
			});
	},
	// 更新头像
	updateAvatar: (jwt, returnedImgUri, onSuccess) => {
		axios
			.post(
				`${baseUrl}/user/user_photo_update`,
				{ photo: returnedImgUri },
				{ headers: { Accept: "application/json", token: jwt } }
			)
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
				} else {
					console.log("updateAvatar API error", res);
				}
			})
			.catch((err) => {
				console.log("updateAvatar API error", err);
			});
	},
	// 上传一张照片
	uploadSinglePicture: (formData, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/util/upload`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					onFail(res);
					console.log("uploadPicture API error 1", res);
				}
			})
			.catch((err) => {
				console.log("uploadPicture API error 2", err);
				onFail(err);
			});
	},
	//上传多张照片
	uploadMultiplePictures: (pictureFormData, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/util/upload_list`, pictureFormData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("res", res);
					onSuccess(res.data.data);
				} else {
					onFail();
					console.log("uploadPictureList API error", res);
				}
			})
			.catch((err) => {
				onFail();
				console.log("uploadPictureList API error", err);
			});
	},
	//获取分类列表
	getCategoryList: (onSuccess) => {
		console.log("getCategoryList");
		axios
			.post(
				`${baseUrl}/project/select_category_list`,

				{
					headers: {
						Accept: "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.msg == "success" || res.data.code === 200) {
					// console.log("getCategoryList", res.data.data);
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("getCategoryList API Error", res);
				}
			})
			.catch((error) => {
				console.log("getCategoryList API Error", error);
			});
	},
	//申请师傅
	applyWorker: (jwt, workerRegisterFormData, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/apply_worker`, workerRegisterFormData, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.msg == "success" || res.data.code === 200) {
					// console.log("success", res.data);
					onSuccess();
				} else if (res.data.code === 500) {
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("applyworker API Error", res);
				}
			})
			.catch((error) => {
				console.log("applyworker API Error", error);
			});
	},
	//更新身份认证
	updateIdentity: (jwt, updateIdentityData, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/update_worker_identity`, updateIdentityData, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("updateIdentity API Error", res);
				}
			})
			.catch((error) => {
				console.log("updateIdentity API Error", error);
			});
	},
	adminUpdateIdentity: (jwt, updateIdentityData, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/admin_update_worker_identity`, updateIdentityData, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("admin_update_worker_identity API Error", res);
				}
			})
			.catch((error) => {
				console.log("admin_update_worker_identity API Error", error);
			});
	},
	//上传保险
	uploadInsurance: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/insert_insurance`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("insert_insurance API Error", res);
				}
			})
			.catch((error) => {
				console.log("insert_insurance API Error", error);
			});
	},
	adminUploadInsurance: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/admin_insert_insurance`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("admin_insert_insurance API Error", res);
				}
			})
			.catch((error) => {
				console.log("admin_insert_insurance API Error", error);
			});
	},
	getUserInfo: (jwt, params, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/user/user_info`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					onFail(res);
					console.log("getUserInfo API Error", res);
				}
			})
			.catch((error) => {
				console.log("getUserInfo API Error", error);
			});
	},
	workStatusReview: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/worker_status_review`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("getUserInfo API Error", res);
				}
			})
			.catch((error) => {
				console.log("getUserInfo API Error", error);
			});
	},
	selectManagementProjectList: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_management_project_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("selectManagementProjectList API Error", res);
				}
			})
			.catch((error) => {
				console.log("selectManagementProjectList API Error", error);
			});
	},
	selectAdminProjectList: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_admin_project_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_admin_project_list API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_admin_project_list API Error", error);
			});
	},
	selectProjectList: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_project_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_project_list API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_project_list API Error", error);
			});
	},
	selectManagementInfoById: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/management/select_management_info_by_id`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_management_info_by_id API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_management_info_by_id API Error", error);
			});
	},
	userPages: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/user/select_customer_user_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("user_page API Error", res);
				}
			})
			.catch((error) => {
				console.log("user_page API Error", error);
			});
	},
	adminPages: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/user/select_system_user_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_system_user_list API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_system_user_list API Error", error);
			});
	},
	addAdmin: (jwt, params, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/user/add_system_user`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("add_system_user API Error", res);
					onFail(res);
				}
			})
			.catch((error) => {
				console.log("add_system_user API Error", error);
				onFail(error);
			});
	},
	exportWorkers: (jwt, params, onSuccess) => {
		window.open(
			`${baseUrl}/worker/worker_page_v2_export_download?fileName=${params.fileName}`
		);
	},
	updateUserSuspend: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/user/update_user_suspend`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				console.log("res", res);
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("update_user_suspend API Error", res);
				}
			})
			.catch((error) => {
				console.log("update_user_suspend API Error", error);
			});
	},
	updateWorkerSuspend: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/update_worker_suspend`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("update_worker_suspend API Error", res);
				}
			})
			.catch((error) => {
				console.log("update_worker_suspend API Error", error);
			});
	},
	manage_update_worker_service_list: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/manage_update_worker_service_list`, payload, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				console.log(res);
				if (res.data.code === 200 && res.data.msg === "success") {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("manage_update_worker_service_list error", res);
				}
			})
			.catch((err) => {
				console.log("manage_update_worker_service_list error", err);
			});
	},

	//添加新用户
	addUser: (jwt, params, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/user/add_user`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("add_user API Error", res);
					onFail(res);
				}
			})
			.catch((error) => {
				console.log("add_user API Error", error);
				onFail(error);
			});
	},
	//删除用户
	deleteUser: (jwt, params, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/user/user_delete`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				console.log("res", res);
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data);
				} else {
					console.log("deleteUser API Error", res);
					onFail(res);
				}
			})
			.catch((error) => {
				console.log("deleteUser API Error", error);
				onFail(error);
			});
	},
	//删除工人师傅
	deleteWorker: (jwt, params, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/worker/worker_delete`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				console.log("res", res);
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data);
				} else {
					console.log("deleteUser API Error", res);
					onFail(res);
				}
			})
			.catch((error) => {
				console.log("deleteUser API Error", error);
				onFail(error);
			});
	},
	//获取工人师傅信息
	selectWorkerInfo: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/select_worker_info_by_id`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_worker_info API Error", res);
				}
			});
	},
	workerPages: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/worker_page_v2`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("user_page API Error", res);
				}
			})
			.catch((error) => {
				console.log("user_page API Error", error);
			});
	},
	selectAfterSalePage: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/after_sale/select_after_sale_page`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_after_sale_page API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_after_sale_page API Error", error);
			});
	},
	selectPopupList: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/select_popup_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_popup_list API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_popup_list API Error", error);
			});
	},
	insertPopup: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/insert_popup`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("insert_popup API Error", res);
				}
			})
			.catch((error) => {
				console.log("insert_popup API Error", error);
			});
	},
	updatePopup: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/update_popup`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("update_popup API Error", res);
				}
			})
			.catch((error) => {
				console.log("update_popup API Error", error);
			});
	},
	selectRentList: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/select_rent_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_rent_list API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_rent_list API Error", error);
			});
	},
	updateRent: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/update_rent`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("update_rent API Error", res);
				}
			})
			.catch((error) => {
				console.log("update_rent API Error", error);
			});
	},
	insertRent: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/insert_rent`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("insert_rent API Error", res);
				}
			})
			.catch((error) => {
				console.log("insert_rent API Error", error);
			});
	},
	selectDiscoverList: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/select_discover_list`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("select_discover_list API Error", res);
				}
			})
			.catch((error) => {
				console.log("select_discover_list API Error", error);
			});
	},
	reviewDiscover: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/util/review_discover`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("review_discover API Error", res);
				}
			})
			.catch((error) => {
				console.log("review_discover API Error", error);
			});
	},
	reviewAfterSale: (jwt, params, onSuccess) => {
		axios
			.post(`${baseUrl}/after_sale/review_after_sale`, params, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg == "success") {
					onSuccess(res.data.data);
				} else {
					console.log("review_after_sale API Error", res);
				}
			})
			.catch((error) => {
				console.log("review_after_sale API Error", error);
			});
	},
	//更新公司信息
	updateWorker: (jwt, updateFormData, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/update_worker`, updateFormData, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg === "success") {
					onSuccess();
				} else {
					console.log("updateWorker error", res);
				}
			})
			.catch((err) => {
				console.log("update_worker error", err);
			});
	},
	//添加牌照
	addLicence: (jwt, uploadLicenceData, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/insert_licence`, uploadLicenceData, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg === "success") {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("uploadLicenceData error", res);
				}
			})
			.catch((err) => {
				console.log("uploadLicenceData error", err);
			});
	},
	//添加牌照
	adminUpdateLicence: (jwt, uploadLicenceData, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/admin_insert_licence`, uploadLicenceData, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg === "success") {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("admin_insert_licence error", res);
				}
			})
			.catch((err) => {
				console.log("admin_insert_licence error", err);
			});
	},
	//更新牌照
	updateLicence: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/update_licence`, payload, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg === "success") {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("update_licence error", res);
				}
			})
			.catch((err) => {
				console.log("update_licence error", err);
			});
	},
	//删除牌照
	deleteLicence: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/delete_licence`, payload, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200 && res.data.msg === "success") {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("delete_licence error", res);
				}
			})
			.catch((err) => {
				console.log("delete_licence error", err);
			});
	},
	//更新服务
	updateServices: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/worker/update_worker_service_list`, payload, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				console.log(res);
				if (res.data.code === 200 && res.data.msg === "success") {
					// console.log("res", res);
					onSuccess();
				} else {
					console.log("update_worker_service_list error", res);
				}
			})
			.catch((err) => {
				console.log("update_worker_service_list error", err);
			});
	},
	//添加工程案例
	addWorkingCase: (jwt, uploadWorkerCaseData, onSuccess) => {
		axios
			.post(
				`${baseUrl}/worker/upload_worker_case_photo_list`,
				uploadWorkerCaseData,
				{ headers: { Accept: "application/json", token: jwt } }
			)
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
				} else {
					console.log("upload_worker_case_photo API error", res);
				}
			})
			.catch((err) => {
				console.log("upload_worker_case_photo API error", err);
			});
	},
	//获取building列表
	getBuildingList: (jwt, onSuccess) => {
		axios
			.post(`${baseUrl}/user/select_build_list`, null, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("getBuildingList", res);
				}
			})
			.catch((err) => {
				console.log("getBuildingList", err);
			});
	},
	//普通用户发布项目
	publishProject: (jwt, publishData, onSuccess) => {
		axios
			.post(`${baseUrl}/project/public_project`, publishData, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
				} else {
					console.log("publishProject error 1", res);
				}
			})
			.catch((err) => {
				console.log("publishProject error 2", err);
			});
	},
	//普通用户获取自己的项目列表
	userProjectList: (jwt, pageData, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_user_project_list`, pageData, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_user_project_list error 1", res);
				}
			})
			.catch((err) => {
				console.log("select_user_project_list error 2", err);
			});
	},
	//普通用户获取自己的售后列表
	userAfterSaleProjectList: (jwt, pageData, onSuccess) => {
		axios
			.post(`${baseUrl}/after_sale/select_customer_after_sale_page`, pageData, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_customer_after_sale_page error 1", res);
				}
			})
			.catch((err) => {
				console.log("select_customer_after_sale_page error 2", err);
			});
	},
	//工人获取项目列表
	workerProjectList: (pageData, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_square_project_list`, pageData, {
				headers: { Accept: "application/json" },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_square_project_list error 1", res);
				}
			})
			.catch((err) => {
				console.log("select_square_project_list error 2", err);
			});
	},
	//获取项目详情
	projectDetail: (jwt, projectId, onSuccess, onFail) => {
		// console.log("jwt", jwt);
		// console.log("projectId", projectId);

		axios
			.post(`${baseUrl}/project/select_project_info`, projectId, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_project_info error 1", res);
					onFail(res.data.message);
				}
			})
			.catch((err) => {
				console.log("select_project_info error 2", err);
				onFail();
			});
	},
	//申请退款
	applyForReturning: (jwt, payload, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/after_sale/create_after_sale`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("create_after_sale error 1", res);
					onFail(res.data.message);
				}
			})
			.catch((err) => {
				console.log("create_after_sale error 2", err);
				onFail();
			});
	},
	//删除项目
	cancelProject: (jwt, payload, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/project/cancel_project`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("cancel_project error 1", res);
					onFail(res.data.message);
				}
			})
			.catch((err) => {
				console.log("cancel_project error 2", err);
				onFail();
			});
	},

	//师傅申请项目
	workerApplyProject: (jwt, payload, onSuccess, onFail) => {
		axios
			.post(`${baseUrl}/project/worker_apply_project`, payload, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
					// console.log("apply project success");
				} else if (res.data.code === 500) {
					onFail(res);
					Toast.show({ type: "error", text1: res.data.msg });
					console.log("worker_apply_project error 1", res);
				}
			})
			.catch((err) => {
				console.log("worker_apply_project error 2", err);
			});
	},
	//获取项目申请人列表
	getApplicantList: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_project_apply_list`, payload, {
				headers: {
					Accept: "application/json",
					token: jwt,
				},
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_project_apply_list error 1", res);
				}
			})
			.catch((err) => {
				console.log("select_project_apply_list error 2", err);
			});
	},
	//判断用户之间是否互为朋友
	judgeFriend: (jwt, friendId, onSuccess) => {
		axios
			.post(`${baseUrl}/user/judge_friend`, friendId, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("judge_friend error 1", res);
				}
			})
			.catch((err) => {
				console.log("judge_friend error 2", err);
			});
	},
	//加为朋友
	makeFriend: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/user/make_friend`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				// console.log(res);
				if (res.data.code !== 200) {
					console.log("make_friend error 11", error);
				} else {
					onSuccess();
				}
			})
			.catch((error) => {
				console.log("make_friend error 22", error);
			});
	},
	//获取朋友列表
	getFriendList: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/user/select_friend_page`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_friend_page error 1", res);
				}
			})
			.catch((error) => {
				console.log("select_friend_page error 2", error);
			});
	},
	//发送报价
	updateApplyProjectPrice: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/update_apply_project_price`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("update_apply_project_price", res);
					onSuccess();
				} else if (res.data.code === 500) {
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("update_apply_project_price error 1", res);
				}
			})
			.catch((error) => {
				console.log("update_apply_project_price error 2", error);
			});
	},
	//支付成功修改Addition状态
	payAddition: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/pay_addition`, payload, {
				headers: { "Content-Type": "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("pay_project", res);
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("pay_addition error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("pay_addition error 1", res);
				}
			})
			.catch((error) => {
				console.log("pay_addition error 2", error);
			});
	},
	//支付成功修改订单状态
	payProject: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/pay_project`, payload, {
				headers: { "Content-Type": "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("pay_project", res);
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("pay_project error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("pay_project error 1", res);
				}
			})
			.catch((error) => {
				console.log("pay_project error 2", error);
			});
	},
	//确定项目的师傅
	confirmProjectWorker: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/user_confirm_project_worker`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("user_confirm_project_worker", res);
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("user_confirm_project_worker error 1", res);

					if (res.data.msg === "Project is not showing now") {
						Toast.show({
							text1: "Project has already accept estimate",
							type: "error",
						});
					} else {
						Toast.show({ text1: res.data.msg, type: "error" });
					}
				} else {
					console.log("user_confirm_project_worker error 1", res);
				}
			})
			.catch((error) => {
				console.log("user_confirm_project_worker error 2", error);
			});
	},
	//添加额外费用
	addAddtionFee: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/project_add_addition`, payload, {
				headers: { "Content-Type": "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("change_project_price", res);
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("project_add_addition error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("project_add_addition error 1", res);
				}
			})
			.catch((error) => {
				console.log("project_add_addition error 2", error);
			});
	},
	//获取额外费用
	getAdditionFee: (jwt, payload, onSuccess) => {
		console.log(jwt);
		axios
			.post(`${baseUrl}/project/select_project_addition`, payload, {
				headers: { "Content-Type": "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("change_project_price", res);
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					console.log("select_project_addition error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("select_project_addition error 1", res);
				}
			})
			.catch((error) => {
				console.log("select_project_addition error 2", error);
			});
	},
	//修改项目最终价格
	changeProjectFinalPrice: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/change_project_price`, payload, {
				headers: { "Content-Type": "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("change_project_price", res);
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("change_project_price error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("change_project_price error 1", res);
				}
			})
			.catch((error) => {
				console.log("change_project_price error 2", error);
			});
	},
	//获取师傅的项目
	getWorkerProjectList: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/select_worker_project_list`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					// console.log("select_worker_project_list", res);
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					console.log("select_worker_project_list error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("select_worker_project_list error 1", res);
				}
			})
			.catch((error) => {
				console.log("select_worker_project_list error 2", error);
			});
	},
	//获取paymentIntent
	getPaymentIntent: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/get_payment_intents`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else if (res.data.code === 500) {
					console.log("get_payment_intents error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("get_payment_intents error 1", res);
				}
			})
			.catch((error) => {
				console.log("get_payment_intents error 2", error);
			});
	},
	//工人完成项目
	finishProject: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/worker_finish_work`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("worker_finish_project error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("worker_finish_project error 1", res);
				}
			})
			.catch((error) => {
				console.log("worker_finish_project error 2", error);
			});
	},
	//用户确认完成项目
	userFinishProject: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/customer_finish_work`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("customer_finish_work error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("customer_finish_work error 1", res);
				}
			})
			.catch((error) => {
				console.log("customer_finish_work error 2", error);
			});
	},
	//开始施工
	startProject: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/project/worker_start_work`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess();
				} else if (res.data.code === 500) {
					console.log("worker_start_work error 1", res);
					Toast.show({ text1: res.data.msg, type: "error" });
				} else {
					console.log("worker_start_work error 1", res);
				}
			})
			.catch((error) => {
				console.log("worker_start_work error 2", error);
			});
	},
	getUserInfoByid: (jwt, payload, onSuccess) => {
		axios
			.post(`${baseUrl}/user/select_user_by_id`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("select_user_by_id error 1", res);
				}
			})
			.catch((error) => {
				console.log("select_user_by_id error 2", error);
			});
	},
	getReferUser: (jwt,payload, onSuccess) => {
		axios
			.post(`${baseUrl}/coupon/find_refer_user`, payload, {
				headers: { Accept: "application/json", token: jwt },
			})
			.then((res) => {
				if (res.data.code === 200) {
					onSuccess(res.data.data);
				} else {
					console.log("getReferUser error 1", res);
				}
			})
			.catch((error) => {
				console.log("getReferUser error 2", error);
			});
	},
};

export default API;
