import * as Localization from "expo-localization";
import { I18n } from "i18n-js";

const registerPage = {
	en: {
		email: "Email",
		emailInput: "Email Address",
		invalidEmail: "The email format is incorrect.",
		password: "Password",
		passwordInput: "Password (at least 6 characters)",
		invalidPassword: "The password length cannot be less than 6 characters.",
		nickname: "Nickname",
		nicknamePlaceholder: "Please provide nickname",
		invalidNickname: "Nickname cannot be empty.",
		address: "Address",
		address1: "Address 1",
		invalidAddress: "Address cannot be empty.",
		address2: "Address 2 (optional)",
		city: "City",
		invalidCity: "City cannot be empty.",
		postcode: "Postcode",
		invalidPostcode: "Postcode cannot be empty.",
		signUp: "Sign Up",
		registerInstruction:
			"If you are a master, after successful registration, you will have the opportunity to choose the master version.",
		registerSuccess: "Success! Please active your account in your email.",
	},
	zh: {
		email: "邮箱",
		emailInput: "请输入Email",
		invalidEmail: "Email的格式不正确",
		password: "密码",
		passwordInput: "密码（至少6位）",
		invalidPassword: "密码长度不能少于6位",
		nickname: "昵称",
		nicknamePlaceholder: "请输入昵称",
		invalidNickname: "昵称不能为空",
		address: "地址",
		address1: "地址 1",
		invalidAddress: "地址不能为空",
		address2: "地址 2 (可选)",
		city: "城市",
		invalidCity: "城市不能为空",
		postcode: "邮编",
		invalidPostcode: "邮编不能为空",
		signUp: "注册",
		registerInstruction: "如果您是师傅，注册成功后，您有机会选择师傅版本",
		registerSuccess: "注册成功\n\n请在邮箱中激活您的账户",
	},
};

const loginPage = {
	en: {
		slogan: "Connect with MY HOME in the simplest way.",
		loginPassword: "Password",
		invalidLoginPsw: "Password cannot be empty",
		loginEmail: "Email",
		invalidLoginEmail: "Email cannot be empty.",
		forgetPsw: "Forget Password?",
		otherLogin: "Try another way to login",
		workerLock: "Your worker account has been suspended.",
		userLock: "Your account has been suspended.",
		newPsw: "Enter new password",
		resetPswSuccess: "Reset password success!",
	},
	zh: {
		slogan: "用最简单的方式，与 MY HOME 互联",
		loginEmail: "请输入Email",
		invalidLoginEmail: "邮箱不能为空。",
		workerLock: "您的师傅账户存在异常情况，已被管理员封禁",
		userLock: "您的账户存在异常情况，已被管理员封禁",
		loginPassword: "请输入密码",
		invalidLoginPsw: "密码不能为空",
		forgetPsw: "忘记密码",
		otherLogin: "其他登录方式",
		newPsw: "输入新密码",
		resetPswSuccess: "重置成功!",
	},
};

const profilePage = {
	en: {
		profileLoginInfo:
			"This page is used to display your information and settings.",
		profileLoginSideInfo: "Please log in to post your requirements.",
	},
	zh: {
		profileLoginInfo: "本页用来显示您的相关信息和设置。",
		profileLoginSideInfo: "请登录发布您的需求",
	},
};

const workerProfilePage = {
	en: {
		profilePageTitle: "Profile",
		companyInformation: "Company Information",
		companyName: "Company Name",
		companyNamePlaceholder: "Please provide company name",
		companyDescription: "Description",
		companyDescriptionPlaceholder: "Please provide company description",
		companyEstablishmentYear: "Year of Establishment",
		companyEstablishmentYearPlaceholder:
			"Please provide company establishment year",
		companyNumberOfEmployees: "Number of employees",
		companyNumberOfEmployeesPlaceholder:
			"Please provide the number of employees in your company",
		language: "Language",
		languagePlaceholder: "Please list the languages you are proficient in",
		companyAddress: "Company Address",
		companyAddressPlaceholder: "Please provide your company's address",
		website: "Website",
		websitePlaceholder: "Please provide your company's website",
		requirements: "Requirements",
		identityLicense: "Identity License",
		completed: "Completed",
		identityLicensePlaceholder: "Please provide your identity license",
		liabilityInsurance: "Liability Insurance",
		liabilityInsurancePlaceholder: "Please provide your liability insurance",
		wsib: "Workplace Safety and Insurance Board(WSIB)",
		wsibPlaceholder: "Please provide your WSIB",
		clearlyPicture: "Please provide a clearly document picture",
		licenses: "Licenses",
		licensesPlaceholder:
			"If your industry requires a license, please provide the relevant information",
		services: "Services",
		servicesPlaceholder:
			"We will send project requirements related to the added service categories to you",
		workerProject: "Projects",
	},
	zh: {
		profilePageTitle: "资料",
		companyInformation: "公司信息",
		companyName: "公司名称",
		companyNamePlaceholder: "请添加您的公司名称",
		companyDescription: "介绍",
		companyDescriptionPlaceholder: "请添加您的公司介绍",
		companyEstablishmentYear: "成立年份（入行年份）",
		companyEstablishmentYearPlaceholder: "请填写公司成立年份（入行年份)",
		companyNumberOfEmployees: "公司人数",
		companyNumberOfEmployeesPlaceholder: "请填写公司人数",
		language: "语言",
		languagePlaceholder: "英语，普通话，粤语",
		companyAddress: "公司地址",
		companyAddressPlaceholder: "请填写公司地址",
		website: "网站",
		websitePlaceholder: "请填写公司网站",
		requirements: "认证",
		identityLicense: "身份认证",
		completed: "已认证",
		identityLicensePlaceholder: "请进行身份认证",
		liabilityInsurance: "责任保险(Liability Insurance)",
		liabilityInsurancePlaceholder: "请提供责任保险",
		wsib: "劳工安全保险(WSIB)",
		wsibPlaceholder: "请提供责任保险 WSIB",
		clearlyPicture: "请拍一张清晰的保险文件照片",
		licenses: "专业牌照",
		licensesPlaceholder: "如果你的行业要求必须具有license，请提供相关信息",
		services: "服务类别",
		servicesPlaceholder:
			"请添加服务，我们将根据添加的服务类别发送相关项目需求给您",
		workerProject: "工程案例",
	},
};

const registerWorkerForm = {
	en: {
		registerWorkerFormTitle: "Choose Services",
		serviceArea: "Choose Service Area",
		selectServiceProvince: "Select service province",
		searchCity: "Search City",
		companyAddressSide:
			"The system will prioritize delivering orders from nearby locations based on your address.",
		street: "Street",
		companyEstablishmentYearSide:
			"If you represent a company, please provide the year of establishment. \nIf you are an individual, please provide the year you entered the industry.",
		companyDes: "Description About Your Company",
		companyDesSide:
			"Please provide a brief introduction of yourself or your company background. \nYou may share your experience in the industry, any training received, expertise, and the relevant professional tools you possess.",
		registerWorkerLicenceTitle: "Please upload your license",
	},
	zh: {
		registerWorkerFormTitle: "选择服务类别",
		serviceArea: "选择服务区域",
		selectServiceProvince: "选择服务省份",
		searchCity: "搜索城市",
		companyAddressSide: "系统将根据您的地址，优先为您派送附近的单。",
		street: "街道",
		companyEstablishmentYearSide:
			"如果你是公司，请填写公司成立年份，如果您是个人，请填写您的入行年份。",
		companyDes: "关于您和您的公司",
		companyDesSide:
			"请简单介绍您或公司背景，您可以介绍您在这个行业的经验，接受过的培训，专业度，以及您拥有的相关专业工具。",
		registerWorkerLicenceTitle: "请上传您的牌照",
	},
};

const editWorkerProfile = {
	en: {
		editWorkerProfileTile: "Company Information",
		cityPlaceholder: "Please provide city",
		postcodePlaceholder: "Please provide postcode",
	},
	zh: {
		editWorkerProfileTile: "公司资料",
		cityPlaceholder: "请填写公司所在城市",
		postcodePlaceholder: "请填写公司邮编",
	},
};

const identityVerificationPage = {
	en: {
		identityVerificationTitle: "Identity Verification",
		identityVerificationSideTitle:
			"Identity verification helps prevent others from misusing your team or company name. Masters need to complete the authentication before sending quotes. Your information is only used for identity verification purposes.",
		identityFistLastName: "First & Last Name",
		identityFistLastNameSide:
			"Please ensure that the name provided matches the name on your driver's license.",
		identityFistName: "First Name",
		identityLastName: "Last Name",
		licenseVerification: "License Verification",
		licenseVerificationSide:
			"Please take a photo or upload the front side of your driver's license",
		facePhoto: "Face Photo",
		selfie:
			"Please take a clear selfie.We will use this photo for facial verification, comparing it with the driver's license picture.",
	},
	zh: {
		identityVerificationTitle: "身份认证",
		identityVerificationSideTitle:
			"身份认证可防止他人盗用您的团队或公司名称，必须完成认证后才可以发送报价，您的信息仅用来进行身份审核。",
		identityFistLastName: "真实姓名",
		identityFistLastNameSide:
			"请用拼音填写您的姓名，您的姓名需和驾照姓名保持一致。",
		identityFistName: "姓",
		identityLastName: "名字",
		licenseVerification: "证件审核",
		licenseVerificationSide: "请上传您驾照的正面照",
		facePhoto: "面部照片",
		selfie:
			"请自拍一张脸不清晰的照片，我们将用此照片与驾照进行核对，验证成功后，自动生成您的头像。",
	},
};
const uploadLicence = {
	en: {
		addLicenceTitle: "Add Licence",
		updateLicenceTitle: "Edit Licence",
		deleteLicenceTitle: "Delete Licence",
		uploadLicenceSideTitle:
			"We verify the license you provide by checking the public licensing database.",
		licenseType: "License Type",
		licenseNumber: "License Number",
		licensePhoto: "License Photo",
		warningDeletion: "Confirm deletion of this license?",
	},
	zh: {
		addLicenceTitle: "添加牌照",
		updateLicenceTitle: "修改牌照",
		deleteLicenceTitle: "删除牌照",
		uploadLicenceSideTitle:
			"我们通过查询public licensing database来验证您提供的license。",
		licenseType: "牌照类型",
		licenseNumber: "牌照号码",
		licensePhoto: "牌照照片",
		warningDeletion: "确认要删除此牌照吗?",
	},
};

const updateWorkingCase = {
	en: {
		updateWorkingCaseTitle: "Project Case",
		projectName: "Project Name",
		projectDescription: "Project Description",
	},
	zh: {
		updateWorkingCaseTitle: "工程案例",
		projectName: "项目名称",
		projectDescription: "项目描述",
	},
};
const ProprietorProfilePage = {
	en: {
		eidtProfile: "Eidt Profile",
		masterVersion: "Master Version",
		joinMaster: "Join us as a master",
	},
	zh: {
		eidtProfile: "编辑资料",
		masterVersion: "师傅版本",
		joinMaster: "师傅加盟",
	},
};

const editProfile = {
	en: {
		profileTitle: "Edit Profile",
		profileUserName: "User Name",
		profileEmail: "Email",
		profilePhone: "Phone",
		addPhone: "Enter your phone number",
		phoneNumber: "Phone number",
		invalidPhoneNumber: "Phone number cannot be empty.",
		Verificationcode: "Verify code",
		invalidCode: "Verify code cannot be empty.",
		sendCode: "Send Code",
		sent: "Sent",
	},
	zh: {
		profileTitle: "资料编辑",
		profileUserName: "Username",
		profileEmail: "邮箱",
		profilePhone: "电话",
		addPhone: "电话",
		phoneNumber: "请输入手机号",
		invalidPhoneNumber: "手机号不能为空。",
		Verificationcode: "手机验证码",
		invalidCode: "验证码不能为空。",
		sendCode: "发送验证码",
		sent: "已发送",
	},
};

const setttingPage = {
	en: {
		setttingPageTitle: "Setting",
		myPhoto: "My Photos",
		myComments: "My Comments",
		mySubscriptions: "My Subscriptions",
		favorites: "Favorites",
		proprietorVersion: "Proprietor Version",
		logOut: "Logout",
	},
	zh: {
		setttingPageTitle: "设置",
		myPhoto: "我的照片",
		myComments: "我的评价",
		mySubscriptions: "我的在线订阅",
		favorites: "赞的照片",
		proprietorVersion: "业主版本",
		logOut: "退出账号",
	},
};
const proprietorHomePage = {
	en: {
		searchServices: "Search Services",
		repairService: "Immediate Repair",
		homeService: "Home Services",
		renoService: " Renovation Services",
	},
	zh: {
		searchServices: "搜索服务",
		repairService: "即时上门维修",
		homeService: "生活服务",
		renoService: " 装修翻新服务",
	},
};

const workerHomePage = {
	en: {
		workerHomePageTitle: "Projects",
		view: "view",
		proContacted: "pro contacted",
		toBeDetermined: "To be determined",
		justNow: "Just Now",
		minutesAgo: "minutes ago",
		hoursAgo: "hours ago",
		daysAgo: "days ago",
		weeksAgo: "weeks ago",
	},
	zh: {
		workerHomePageTitle: "项目",
		view: "查看",
		proContacted: "pro 已联系",
		toBeDetermined: "待定",
		justNow: "刚刚",
		minutesAgo: "分钟前",
		hoursAgo: "小时前",
		daysAgo: "天前",
		weeksAgo: "周前",
	},
};

const publishProject = {
	en: {
		selectAddress: "Select address",
		budgetRange: "Budget Range",
		projectDate: "Expected Start Date",
		projectTime: "Expected Time Range",
		sideTimeInstruction: "Please select one or more time slots",
		projectDateAndTime: "Expected Start Date And Time",
		uploadProjectPhotoInstruction:
			"Photos can provide the master with more comprehensive information and help you get a more accurate estimate!",
		uploadPictures: "Upload Pictures（Max 3）",
		preview: "Preview",
		budget: "Budget",
		projectPictures: "Project Pictures",
		publishSuccessfully: "Publish Successfully",
		publishSuccessfullyInstruction:
			"Worker will contact you, please pay attention to check the message!",
		homePage: "Home Page",
	},
	zh: {
		selectAddress: "选择地址",
		budgetRange: "预算范围",
		projectDate: "期望开工日期",
		projectTime: "期望开工时间",
		sideTimeInstruction: "请选择一个或多个日期",
		projectDateAndTime: "期望开工日期与时间",
		uploadProjectPhotoInstruction:
			"照片可为师傅提供更全面的信息，帮助你获取更精准的报价！",
		uploadPictures: "上传照片（最多 3张）",
		preview: "预览",
		budget: "预算",
		projectPictures: "项目图片",
		publishSuccessfully: "发布成功",
		publishSuccessfullyInstruction: "师父会尽快与您联系，请注意查收消息！",
		homePage: "回到主页",
	},
};

const myProjects = {
	en: {
		noProjects: "No Projects Found",
		inProgress: "In Progress",
		finished: "Finished",
		status: "Status",
		contacts: "Contacts",
		worker: "Worker",
		workerPorjectsTitle: "Work Projects",
	},
	zh: {
		noProjects: "您还未有任何项目！",
		inProgress: "In Progress",
		finished: "Finished",
		status: "状态",
		contacts: "报价人数",
		worker: "工人",
		workerPorjectsTitle: "我的工程",
	},
};
const projectDetail = {
	en: {
		projectDetail: "Project Detail",
		report: "Report",
		refund: "After-Sale",
		remark: "Remark",
		expectedDate: "Expected Start Dates And Time",
		price: "Price",
		location: "Location",
		applyProject: "Apply Project",
		apply: "Apply",
		applySuccess: `Applied successfully!\n\nPlease waitting for landlord to contact`,
		seeEstimate: "See Estimate",
		selectDateAndTime: "Select Working Start Date And Time",
		addAdditionFee: "Add Addition Fee",
		accessConstructionSite: "Access Construction Site",
		finishProject: "Finish Project",
		warning: "Warning",
		changedEstimate: "You have already added addition fee",
		onlyOneChance: "You have only one chance to add addition fee",
		enterAdditionFee: "Enter Addition Fee Amount",
		chatWithLandloard: "Chat With Landloard",
		landloard: "Landloard",
		serviceFee: "Included a $75 assess fee?",
		paymentStatus: "Payment Status",
		refunding: "Apply for after-sale",
		reason: "Please enter the reason",
		refundPicture: "Please provide pictures",
		successText:
			"Your application has been submitted. Please wait patiently for customer service to process it.",
	},
	zh: {
		projectDetail: "项目信息",
		report: "投诉",
		refund: "售后",
		expectedDate: "开工时间",
		remark: "备注",
		price: "价格",
		location: "地点",
		applyProject: "申请项目",
		apply: "发送",
		seeEstimate: "查看报价",
		applySuccess: `申请成功!\n\n请等待业主联系`,
		selectDateAndTime: "选择开工日期和时间",
		addAdditionFee: "添加额外费用",
		accessConstructionSite: "进驻场地",
		finishProject: "完成项目",
		warning: "警告",
		changedEstimate: "您已经添加过额外费用了",
		onlyOneChance: "您只有一次添加额外费用的机会",
		enterAdditionFee: "输入额外费用的价格",
		chatWithLandloard: "联系业主",
		serviceFee: "已包含$75的上门费？",
		landloard: "业主",
		paymentStatus: "支付状态",
		refunding: "申请售后",
		reason: "请填写售后理由",
		refundPicture: "请添加照片",
		successText: "您的申请已提交，请耐心等候客服处理",
	},
};
const applicantList = {
	en: {
		applicantListTitle: "Applicant List",
		chat: "Chat With Worker",
		selectedDateAndTime: "Selected Date and Time",
	},
	zh: {
		applicantListTitle: "项目报价",
		chat: "联系师傅",
		selectedDateAndTime: "选择的日期与时间",
	},
};
const chatRoom = {
	en: {
		estimate: "Estimate",
		sendEstimate: "Send Estimate",
		accept: "Accept",
		getProjectInfo: "Get Project Info",
	},
	zh: {
		estimate: "报价",
		sendEstimate: "发送报价",
		accept: "接受报价",
		getProjectInfo: "查看项目信息",
	},
};
const statusTable = {
	en: {
		reviewing: "Reviewing",
		showing: "Showing",
		waitingProcess: "Awaiting",
		constructionFinished: "Constractor Finished",
		waittingPayment: "Waitting Payment",
		canceled: "Canceled",
		rejected: "Rejected",
		waittingPayment: "Waitting To Pay",
		finishPayment: "Paid",
	},
	zh: {
		reviewing: "Reviewing",
		showing: "Showing",
		waitingProcess: "Waiting Process",
		constructionFinished: "Constractor Finished",
		waittingPayment: "等待支付",
		canceled: "Cancel",
		rejected: "Rejected",
		waittingPayment: "等待支付",
		finishPayment: "已支付",
	},
};

const uploadPictureOption = {
	en: {
		camera: "Camera",
		library: "Photo",
	},
	zh: {
		camera: "相机",
		library: "相册",
	},
};

const agreement = {
	en: {
		IAgree: "I agree to the",
		ContractorAgreement: "contractor agreement",
	},
	zh: {
		IAgree: "阅读并同意",
		ContractorAgreement: "建筑师合约",
	},
};
const buttonText = {
	en: {
		start: "Start",
		next: "Next",
		submit: "Submit",
		loginButton: "Login",
		registerButton: "Sign up",
		updateButton: "Update",
		activated: "Activated",
		loginSignUp: "Login or Sign up",
		deleteProject: "Delete Project",
		delete: "Delete",
		add: "Add",
		pay: "Pay",
		payAdditionFee: "Pay Addition Fee",
		send: "Send",
		resetPsw: "Reset Password",
		returnLogin: "Return to login",
		continue: "Continue",
		change: "Change",
		cancel: "Cancel",
		backToUser: "Back To Proprietor Version",
		gotIt: "Got it",
	},
	zh: {
		start: "开始",
		next: "下一步",
		submit: "提交",
		loginButton: "登录",
		registerButton: "注册",
		updateButton: "更新",
		activated: "已激活",
		loginSignUp: "登录或注册",
		delete: "删除",
		deleteProject: "删除项目",
		add: "添加",
		pay: "支付",
		payAdditionFee: "支付额外费用",
		send: "发送",
		resetPsw: "重置密码",
		returnLogin: "返回登录",
		continue: "继续",
		change: "修改",
		cancel: "取消",
		backToUser: "返回业主账户",
		gotIt: "知道了",
	},
};

const en = {
	home: "Home",
	project: "My Projects",
	estimate: "Estimate",
	discovery: "Discovery",
	message: "Message",
	profile: "Profile",
	...loginPage.en,
	...registerPage.en,
	...profilePage.en,
	...workerProfilePage.en,
	...editWorkerProfile.en,
	...identityVerificationPage.en,
	...uploadPictureOption.en,
	...buttonText.en,
	...uploadLicence.en,
	...updateWorkingCase.en,
	...editProfile.en,
	...ProprietorProfilePage.en,
	...setttingPage.en,
	...registerWorkerForm.en,
	...proprietorHomePage.en,
	...workerHomePage.en,
	...publishProject.en,
	...myProjects.en,
	...statusTable.en,
	...projectDetail.en,
	...applicantList.en,
	...chatRoom.en,
	...agreement.en,
};

const zh = {
	home: "首页",
	project: "我的项目",
	estimate: "报价",
	discovery: "发现",
	message: "消息",
	profile: "资料",
	...loginPage.zh,
	...registerPage.zh,
	...profilePage.zh,
	...workerProfilePage.zh,
	...editWorkerProfile.zh,
	...identityVerificationPage.zh,
	...uploadPictureOption.zh,
	...buttonText.zh,
	...uploadLicence.zh,
	...updateWorkingCase.zh,
	...editProfile.zh,
	...ProprietorProfilePage.zh,
	...setttingPage.zh,
	...registerWorkerForm.zh,
	...proprietorHomePage.zh,
	...workerHomePage.zh,
	...publishProject.zh,
	...myProjects.zh,
	...statusTable.zh,
	...projectDetail.zh,
	...applicantList.zh,
	...chatRoom.zh,
	...agreement.zh,
};

const translations = {
	en: en,
	zh: zh,
};

const i18n = new I18n(translations);

i18n.locale = Localization.locale;
i18n.enableFallback = true;

export default i18n;
