import { createContext, useContext, useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { getUserInfo } from '../Util/api';
import { DeviceEventEmitter } from "react-native";
import i18n from "../Util/supportedLanguage";
const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [isUserSuspend, setIsUserSuspend] = useState(false);
	const [role, setRole] = useState(null);
	const [worker, setWorker] = useState(null);
	const [isWorkerSuspend, setIsWorkerSuspend] = useState(false);
	const [jwt, setJwt] = useState(null);
	const [appVersion, setAppVersion] = useState("proprietor");
	const [languageVersion, setLanguageVersion] = useState("en");
	const logOut = () => {
		setUser(null);
		setJwt(null);
		setWorker(null);
		setAppVersion("proprietor");
		setIsUserSuspend(false);
		setIsWorkerSuspend(false);
		AsyncStorage.removeItem("jwt");
	};

	useEffect(() => {
		autoLogin();
		if (i18n.locale.includes("zh")) {
			setLanguageVersion("zh");
		} else {
			setLanguageVersion("en");
		}
	}, []);

	// useEffect(() => {
	// 	console.log("worker", worker);
	// }, [worker]);
	// useEffect(() => {
	// 	console.log("appVersion", appVersion);
	// }, [appVersion]);
	// useEffect(() => {
	// 	console.log("showFailModal", showFailModal);
	// }, [showFailModal]);

	const autoLogin = async () => {
		try {
			const roleData = (await AsyncStorage.getItem("role")) || null;
			console.log(roleData)
			if (roleData) {
				setRole(roleData);
			}

			const jwtAsync = await AsyncStorage.getItem("jwt");
			if (jwtAsync) {
				setJwt(jwtAsync);

				try {
					const userData = await getUserInfo();
					setUser(userData);
					if (userData.role === "admin" || userData.role === "system_user") {
						setAppVersion("admin");
					} else {
						setAppVersion("proprietor");
					}
				} catch (error) {
					if (error.response && error.response.status === 401) {
						// Token 可能已过期，清除本地存储并重置状态
						logOut();
					} else {
						console.error('获取用户信息失败:', error);
					}
				}
			}
		} catch (err) {
			console.log("API Error2", err);
		}
	};

	useEffect(() => {
		const reloadUserData = () => {
			// console.log("reloadJwt", jwt);
			API.getUserInfo(jwt, {}, (userData) => {
				setUser(userData);
			});
		};

		const reloadWorkerData = (token = jwt) => {
			API.getWorkerInfo(token, (workerData) => {
				setWorker(workerData);
				if (workerData.isSuspend === 1) {
					setIsWorkerSuspend(true);
				}
				setAppVersion("worker");
			});
		};

		DeviceEventEmitter.addListener("reloadUserData", reloadUserData);
		DeviceEventEmitter.addListener("reloadWorkerData", reloadWorkerData);

		return () => {
			DeviceEventEmitter.removeAllListeners("reloadUserData", reloadUserData);
			DeviceEventEmitter.removeAllListeners(
				"reloadWorkerData",
				reloadWorkerData
			);
		};
	}, [jwt]);

	const globalContext = {
		role,
		user,
		jwt,
		worker,
		setUser: (userData) => {
			if (userData && userData.role) {
				AsyncStorage.setItem("role", userData.role);
			}
			setUser(userData);
		},
		isWorkerSuspend,
		setIsWorkerSuspend: (isSuspend) => {
			setIsWorkerSuspend(isSuspend);
		},
		isUserSuspend,
		setIsUserSuspend: (isSuspend) => {
			setIsUserSuspend(isSuspend);
		},
		setWorker: (workerData) => {
			setWorker(workerData);
		},
		setRole: (data) => {
			setRole(data);
			AsyncStorage.setItem("role", data);
		},
		setJwt: (jwt) => {
			setJwt(jwt);
			AsyncStorage.setItem("jwt", jwt);
		},
		languageVersion,
		appVersion,
		setAppVersion: (version) => {
			setAppVersion(version);
		},
		logOut,
		setShowFailModal: (visible) => {
			setShowFailModal(visible);
		},
	};

	return (
		<GlobalContext.Provider value={globalContext}>
			{children}

		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => {
	return useContext(GlobalContext);
};
