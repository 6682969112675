import React, { useEffect, useState } from 'react';
import { View, Alert, Image, TextInput, ActivityIndicator, Modal, TouchableOpacity, FlatList, Text, StyleSheet } from "react-native";
import Common from '../CommonStyles/common';
import { useGlobalContext } from "../GlobalContext/GlobalContext";
import { Pagination, BanModal, CustomTextInput, RelativeTime, CustomTitle1, CustomTitle, CustomText, CustomButton, CustomButton1, SearchBarAdmin, RelativeTimeString } from "./WebComponent"
import WebAlert from './Alert'
import { getAdminList, addAdmin, deleteAdmin } from '../Util/api';

const pageSize = 10
const date = new Date();
date.setMonth(date.getMonth() - 3);
const threeMonthsAgo = date.toISOString().slice(0, 10);

export default function AdminManager() {
    const { user, jwt, setUser, setJwt, role, setRole, } = useGlobalContext();
    const [cpage, setCPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchData, setSearchData] = useState({ code: "", suspend: 2, startDate: threeMonthsAgo, endDate: new Date().toISOString().slice(0, 10) });

    const [addUserModal, setAddUserModal] = useState(false)

    const [viewUserModal, setViewUserModal] = useState(false)
    const [userData, setUserData] = useState(null)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [nickName, setNickName] = useState("")

    useEffect(() => {
        fetchAdminList();
    }, [])

    const fetchAdminList = async () => {
        try {
            const response = await getAdminList(null);
            console.log("111", response)
            setData(response.items);
            setTotalItems(response.items.length);
            setTotalPages(1);
        } catch (error) {
            console.error('获取管理员列表失败:', error);
            alert('Failed to fetch admin list');
        }
    };

    const handlePageChange = (page) => {
        setCPage(page);
        fetchAdminList();
    };

    const itemOnPress = (data) => {
        console.log(data);
        setUserData(data);
        setViewUserModal(true);
    }

    const addUserOnPress = async () => {
        try {
            await addAdmin({
                email: email,
                name: nickName,
            });
            alert('Admin added successfully');
            setAddUserModal(false);
            fetchAdminList();
        } catch (error) {
            console.error('添加管理员失败:', error);
            alert('Failed to add admin');
        }
    }

    const deleteUserOnPress = (data) => {
        console.log(data)
        WebAlert(
            "",
            "Are you sure you want to delete this admin?",
            [
                {
                    text: "Cancel",
                    onPress: () => console.log("取消删除"),
                    style: "cancel"
                },
                {
                    text: "Confirm",
                    onPress: async () => {
                        try {
                            await deleteAdmin(data._id);
                            alert('Admin deleted successfully');
                            fetchAdminList();
                        } catch (error) {
                            console.error('删除管理员失败:', error);
                            alert('Failed to delete admin');
                        }
                    }
                }
            ],
            { cancelable: false }
        );
    }

    return (
        <View style={styles.container}>
            <SearchBarAdmin
                searchData={searchData}
                placeholder={'Please enter the user ID'}
                status={'Choose'}
                onPress={() => {
                    setAddUserModal(true)
                }}
                onSearch={(searchTerm, startDate, endDate, status, suspend) => {
                    // setSearchData({
                    //     code: searchTerm,
                    //     startDate: startDate,
                    //     endDate: endDate,
                    //     suspend: suspend
                    // });
                    // fetchAdminList();
                }}
            />

            <View style={{
                marginLeft: 25 * Common.WIDTH_DIVISOR,
                flexDirection: "row",
                width: 1600 * Common.WIDTH_DIVISOR,
                borderWidth: 1,
                borderColor: "rgba(217, 217, 217, 1.0)",
                height: 50 * Common.WIDTH_DIVISOR,
                marginTop: 10,
            }}>
                <CustomTitle title="User Name" width="15%" />
                <CustomTitle title="E-mail" width="15%" />
                <CustomTitle title="Register Time" width="15%" />
                <CustomTitle title="Operation" width="15%" />
            </View>

            <FlatList
                style={{
                    marginTop: 20,
                    width: 1600 * Common.WIDTH_DIVISOR,
                    marginLeft: 25 * Common.WIDTH_DIVISOR,
                }}
                data={data}
                scrollEnabled={false}
                renderItem={({ item, index }) => {
                    return (
                        <TouchableOpacity onPress={() => { itemOnPress(item) }} key={index}>
                            <View style={{
                                width: "100%",
                                height: 60 * Common.WIDTH_DIVISOR,
                                flexDirection: "row",
                                borderBottomColor: "rgba(156, 156, 156, 1.0)",
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                            }}>
                                <CustomText title={item.name} width="15%" />
                                <CustomText title={item.email} width="15%" />
                                <CustomText title={RelativeTimeString(item.createdAt)} width="15%" />
                                <View style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "15%"
                                }}>
                                    <CustomButton title={"Delete"} onPress={() => { deleteUserOnPress(item) }} style={{ marginLeft: 10 * Common.WIDTH_DIVISOR }} />
                                </View>
                            </View>
                        </TouchableOpacity>
                    )
                }}
            />

            <Pagination
                cpage={cpage}
                totalItems={totalItems}
                totalPage={totalPages}
                itemsPerPage={pageSize}
                onPageChange={handlePageChange}
            />
            <Modal
                transparent={true}
                visible={addUserModal}
            >
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}>
                    <View style={{
                        marginTop: 100 * Common.WIDTH_DIVISOR,
                        width: 894 * Common.WIDTH_DIVISOR,
                        height: 700 * Common.WIDTH_DIVISOR,
                        borderRadius: 4,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1

                    }}>
                        <Image style={{
                            marginTop: 24 * Common.WIDTH_DIVISOR,
                            width: 82 * Common.WIDTH_DIVISOR,
                            height: 82 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }} source={require("../../resource/Web/people-fill.png")} />

                        <Text style={{
                            marginTop: 8 * Common.WIDTH_DIVISOR,
                            fontSize: 18 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#000000"
                        }}>Register / Edit Profile</Text>

                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Image style={{
                                marginLeft: 60 * Common.WIDTH_DIVISOR,
                                width: 30 * Common.WIDTH_DIVISOR,
                                height: 30 * Common.WIDTH_DIVISOR,
                                alignSelf: "center",
                            }} source={require("../../resource/Web/people-fill.png")} />

                            <Text style={{
                                marginLeft: 12 * Common.WIDTH_DIVISOR,
                                fontSize: 18 * Common.WIDTH_DIVISOR,
                                fontWeight: "400",
                                textAlign: "center",
                                color: "#108693"
                            }}>User</Text>
                        </View>

                        <View style={{
                            marginTop: 6 * Common.WIDTH_DIVISOR,
                            width: 803 * Common.WIDTH_DIVISOR,
                            height: 1,
                            backgroundColor: "rgba(24, 134, 168, 1.0)",
                            alignSelf: "center",
                        }}></View>

                        <View style={{
                            marginTop: 28 * Common.WIDTH_DIVISOR,
                            flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                            height: 80 * Common.WIDTH_DIVISOR
                        }}>
                            <CustomTextInput
                                title={"Email Registration"}
                                placeholder={"Please enter your email"}
                                onChangeText={(text) => {
                                    setEmail(text)
                                }}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />
                            <CustomTextInput
                                title={"Name"}
                                placeholder={"Please enter name"}
                                onChangeText={(text) => {
                                    setNickName(text)
                                }}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />
                        </View>
                       

                        <View style={{
                            flexDirection: "row",
                            marginTop: 40 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }}>
                            <CustomButton1
                                style={{

                                }}
                                title={"Confirm"}
                                onPress={addUserOnPress} />

                            <CustomButton
                                style={{
                                    marginLeft: 20 * Common.WIDTH_DIVISOR
                                }}
                                title={"Back"}
                                onPress={() => {
                                    setAddUserModal(false)
                                }} />
                        </View>

                    </View>
                </View>
            </Modal>

            <Modal
                transparent={true}
                visible={viewUserModal}
            >
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}>
                    <View style={{
                        marginTop: 100 * Common.WIDTH_DIVISOR,
                        width: 894 * Common.WIDTH_DIVISOR,
                        height: 500 * Common.WIDTH_DIVISOR,
                        borderRadius: 4,
                        backgroundColor: "#FFFFFF",
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowOffset: {
                            width: 0,
                            height: 4
                        },
                        shadowRadius: 4,
                        shadowOpacity: 1

                    }}>
                        <Image style={{
                            marginTop: 24 * Common.WIDTH_DIVISOR,
                            width: 82 * Common.WIDTH_DIVISOR,
                            height: 82 * Common.WIDTH_DIVISOR,
                            alignSelf: "center",
                        }} source={require("../../resource/Web/people-fill.png")} />

                        <Text style={{
                            marginTop: 8 * Common.WIDTH_DIVISOR,
                            fontSize: 18 * Common.WIDTH_DIVISOR,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#000000"
                        }}>资料</Text>

                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Image style={{
                                marginLeft: 60 * Common.WIDTH_DIVISOR,
                                width: 30 * Common.WIDTH_DIVISOR,
                                height: 30 * Common.WIDTH_DIVISOR,
                                alignSelf: "center",
                            }} source={require("../../resource/Web/people-fill.png")} />

                            <Text style={{
                                marginLeft: 12 * Common.WIDTH_DIVISOR,
                                fontSize: 18 * Common.WIDTH_DIVISOR,
                                fontWeight: "400",
                                textAlign: "center",
                                color: "#108693"
                            }}>User</Text>
                        </View>

                        <View style={{
                            marginTop: 6 * Common.WIDTH_DIVISOR,
                            width: 803 * Common.WIDTH_DIVISOR,
                            height: 1,
                            backgroundColor: "rgba(24, 134, 168, 1.0)",
                            alignSelf: "center",
                        }}></View>

                        <View style={{
                            marginTop: 28 * Common.WIDTH_DIVISOR,
                            flexDirection: "row", width: 368 * Common.WIDTH_DIVISOR,
                            height: 80 * Common.WIDTH_DIVISOR
                        }}>
                            <CustomTextInput
                                title={"Email Registration"}
                                placeholder={"Please enter your email"}
                                onChangeText={(text) => {
                                    console.log(text)
                                }}
                                editable={false}
                                value={userData ? userData.email : ""}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />
                            <CustomTextInput
                                title={"Nick Name"}
                                placeholder={"Please enter your nickname"}
                                editable={false}
                                value={userData ? userData.name : ""}
                                style={{ marginLeft: 60 * Common.WIDTH_DIVISOR }}
                            />
                        </View>


                        <View style={{
                            flexDirection: "row",
                            marginTop: 40 * Common.WIDTH_DIVISOR,
                            alignSelf: "center"
                        }}>
                            <CustomButton
                                style={{
                                    marginLeft: 20 * Common.WIDTH_DIVISOR
                                }}
                                title={"Back"}
                                onPress={() => {
                                    setViewUserModal(false)
                                }} />
                        </View>

                    </View>
                </View>
            </Modal>
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#fff",
        width: "100%"
    },
    title: {
        fontSize: 16 * Common.WIDTH_DIVISOR,
        fontWeight: "400",
        fontStyle: "normal",
        textAlign: "center",
        color: "#1886A8"
    },
    des: {
        fontSize: 16 * Common.WIDTH_DIVISOR,
        fontWeight: "400",
        fontStyle: "normal",
        lineHeight: 16,
        textAlign: "center",
        color: "#000000"
    },
    frameChildLayout: {
        height: 45,
    },
    goTypo: {
        textAlign: "left",
        fontFamily: "Inter-Regular",
    },
    frameChild: {
        top: 0,
        left: 514,
        backgroundColor: "#108693",
        width: 88
    },
    go: {
        marginTop: 7,
        left: 541,
        fontSize: 25,
        color: "#fff"
    },
    text3: {
        top: 13,
        left: 55,
        fontSize: 15,
        color: "#8b8b8b"
    },
    rectangleParent: {
        borderRadius: 100,
        borderStyle: "solid",
        borderColor: "#d2d2d2",
        borderWidth: 1,
        width: 602,
        height: 45,
        overflow: "hidden"
    },
    text4: {
        top: 210,
        fontSize: 20,
        width: 584,
        fontFamily: "Inter-Regular",
        color: "#656464"
    },
    text5: {
        top: 144,
        fontSize: 45,
        fontWeight: "600",
        fontFamily: "Inter-SemiBold",
        width: 514,
        height: 57
    },
    groupIcon: {
        height: "59.63%",
        width: "18.75%",
        top: "49.89%",
        right: "26.67%",
        bottom: "-9.52%",
        left: "54.58%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden"
    },
    frameParent: {
        backgroundColor: "#fff",
        flex: 1,
        width: "100%",
        height: 465,
        overflow: "hidden"
    }
});