import React, { useState, useEffect } from 'react';
import { View, Image, FlatList, TouchableOpacity, Modal, StyleSheet } from 'react-native';
import { getCategoryList, createCategory, updateCategory, deleteCategory } from '../Util/api';
import { CustomButton, CustomTextInput, CustomTitle, CustomText, CustomPicker } from './WebComponent';
import Common from '../CommonStyles/common';
import WebAlert from './Alert';
import * as ImagePicker from 'expo-image-picker';
import { Platform } from 'react-native';
import { API_BASE_URL } from '../Util/api';  // 导入 API_BASE_URL

export default function CategoryManager() {
  const [categories, setCategories] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [name, setName] = useState('');
  const [parentId, setParentId] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await getCategoryList();
      setCategories(data.categories);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('parentCategory', parentId);

      if (image) {
        const imageFile = await fetch(image).then(r => r.blob())
        formData.append('image', imageFile);
      }

      await createCategory(formData);
      fetchCategories();
      setModalVisible(false);
    } catch (error) {
      console.error('Failed to add category:', error);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('parentCategory', parentId);

      if (image) {
        const imageFile = await fetch(image).then(r => r.blob())
        formData.append('image', imageFile);
      }

      await updateCategory(currentCategory._id, formData);
      fetchCategories();
      setModalVisible(false);
    } catch (error) {
      console.error('Failed to update category:', error);
    }
  };

  const handleDeleteCategory = async (id) => {
    WebAlert(
      "",
      "Are you sure you want to delete this category?",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel delete"),
          style: "cancel"
        },
        {
          text: "Confirm",
          onPress: async () => {
            try {
              await deleteCategory(id);
              fetchCategories();
            } catch (error) {
              console.error('Failed to delete category:', error);
            }
          }
        }
      ],
      { cancelable: false }
    );
  };

  const pickImage = async () => {
    if (Platform.OS === 'web') {
      // Web 平台使用 input 元素选择文件
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          setImage(event.target.result);
        };
        reader.readAsDataURL(file);
      };
      input.click();
    } else {
      // 移动平台使用 ImagePicker
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        setImage(result.uri);
      }
    }
  };

  const renderCategoryItem = ({ item }) => (
    <View style={styles.categoryItem}>
      <View style={{ width: "25%", height: 100, justifyContent: "center", alignItems: "center" }}> <Image
        source={{ uri: item.imageUrl ? `${API_BASE_URL.replace('/lmback', '')}${item.imageUrl}` : 'https://via.placeholder.com/50' }}
        style={styles.categoryImage}
      /></View>
      <CustomText title={item.name} width="25%" />
      <CustomText title={item.parentCategory ? item.parentCategory.name : 'None'} width="25%" />
      <View style={styles.actionButtons}>
        <CustomButton
          title="Edit"
          onPress={() => {
            setCurrentCategory(item);
            setName(item.name);
            setParentId(item.parentCategory ? item.parentCategory._id : '');
            setImage(item.imageUrl ? `${API_BASE_URL.replace('/lmback', '')}${item.imageUrl}` : null);
            setModalVisible(true);
          }}
          style={styles.actionButton}
        />
        <CustomButton
          title="Delete"
          onPress={() => handleDeleteCategory(item._id)}
          style={styles.actionButton}
        />
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <CustomButton
        title="Add Category"
        onPress={() => {
          setCurrentCategory(null);
          setName('');
          setParentId('');
          setImage(null);
          setModalVisible(true);
        }}
        style={styles.addButton}
      />
      <View style={styles.headerRow}>
        <CustomTitle title="Image" width="25%" />
        <CustomTitle title="Name" width="25%" />
        <CustomTitle title="Parent Category" width="25%" />
        <CustomTitle title="Operation" width="25%" />
      </View>
      <FlatList
        data={categories}
        renderItem={renderCategoryItem}
        keyExtractor={item => item._id}
        style={styles.list}
      />
      <Modal
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        transparent={true}
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalContent}>
            <CustomTextInput
              title="Name"
              value={name}
              onChangeText={setName}
            />
            <CustomPicker
              title="Parent Category"
              selectedValue={parentId}
              onValueChange={(itemValue) => setParentId(itemValue)}
              items={categories.map(cat => ({ label: cat.name, value: cat._id }))}
            />
            <TouchableOpacity onPress={pickImage} style={styles.imagePicker}>
              {image ? (
                <Image source={{ uri: image.startsWith('http') ? image : `${API_BASE_URL.replace('/lmback', '')}${image}` }} style={styles.previewImage} />
              ) : (
                <CustomText title="Select Image" />
              )}
            </TouchableOpacity>
            <View style={styles.modalButtons}>
              <CustomButton
                title={currentCategory ? "Update Category" : "Add Category"}
                onPress={currentCategory ? handleUpdateCategory : handleAddCategory}
                style={styles.modalButton}
              />
              <CustomButton
                title="Cancel"
                onPress={() => setModalVisible(false)}
                style={styles.modalButton}
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  addButton: {
    width: 120,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
  headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#ccc',
    paddingBottom: 10,
    marginBottom: 10,
  },
  list: {
    flex: 1,
  },
  categoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  categoryImage: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  actionButtons: {
    flexDirection: 'row',
    width: '25%',
    justifyContent: 'center',
  },
  actionButton: {
    marginLeft: 10,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    width: '40%',
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  modalButton: {
    width: 120,
    marginHorizontal: 10,
  },
  imagePicker: {
    width: 100,
    height: 100,
    borderWidth: 1,
    borderColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  previewImage: {
    width: 98,
    height: 98,
  },
});